var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          attrs: {
            width: "450",
            color: "primary lighten-1",
            "loader-height": "6",
          },
        },
        [
          _c("v-card-title", [_vm._v("Experiment")]),
          _vm._v(" "),
          _c("v-card-text", [
            _vm._v(
              "\n        Welcome to the teaching assistant experiment. This experiment will consist of two tasks, each of which is composed of a "
            ),
            _c("b", [_vm._v("study")]),
            _vm._v(" section, a "),
            _c("b", [_vm._v("quiz")]),
            _vm._v(" section, and an "),
            _c("b", [_vm._v("exam")]),
            _vm._v(
              " section.\n        You will have an hour to complete each task.\n        You are asked to study the material provided for each task, and complete the quiz and exam to the best of your ability. During the quiz, you will be able to review the study material.\n        However, during the exam, you will not have access to the study material.\n        You will have access to a "
            ),
            _c("b", [_vm._v("virtual assistant")]),
            _vm._v(
              " that can answer questions about the learning material during the "
            ),
            _c("b", [_vm._v("learning and quiz sections only")]),
            _vm._v(
              ".\n        To ask the virtual assistant a question, type in the chatbox on the right hand side and send a message. Please be patient for the agent to respond, and do not send the same message twice if it is taking a moment to respond.\n        The text below will indicate for which task the assistant is available.\n        "
            ),
            _c("br"),
            _c("br"),
            _vm._v("\n        " + _vm._s(this.condition_text) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "success" },
              on: {
                click: function ($event) {
                  return _vm.go_to_condition()
                },
              },
            },
            [_vm._v("begin")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }