import LifecycleCost from './AERO401/Slide4.png'
import NASAModel from './AERO401/Slide3.png'
import Approaches from './AERO401/Slide5.png'



import AERO401Slide1 from './AERO401/Slide1.png'
import AERO401Slide2 from './AERO401/Slide2.png'
import AERO401Slide3 from './AERO401/Slide3.png'
import AERO401Slide4 from './AERO401/Slide4.png'
import AERO401Slide5 from './AERO401/Slide5.png'
import AERO401Slide6 from './AERO401/Slide6.png'
import AERO401Slide7 from './AERO401/Slide7.png'
import AERO401Slide8 from './AERO401/Slide8.png'
import AERO401Slide9 from './AERO401/Slide9.png'
import AERO401Slide10 from './AERO401/Slide10.png'
import AERO401Slide11 from './AERO401/Slide11.png'
import AERO401Slide12 from './AERO401/Slide12.png'
import AERO401Slide13 from './AERO401/Slide13.png'
import AERO401Slide14 from './AERO401/Slide14.png'
import AERO401Slide15 from './AERO401/Slide15.png'
import AERO401Slide16 from './AERO401/Slide16.png'
import AERO401Slide17 from './AERO401/Slide17.png'
import AERO401Slide18 from './AERO401/Slide18.png'
import AERO401Slide19 from './AERO401/Slide19.png'
import AERO401Slide20 from './AERO401/Slide20.png'
import AERO401Slide21 from './AERO401/Slide21.png'
import AERO401Slide22 from './AERO401/Slide22.png'
import AERO401Slide23 from './AERO401/Slide23.png'
import AERO401Slide24 from './AERO401/Slide24.png'
import AERO401Slide25 from './AERO401/Slide25.png'
import AERO401Slide26 from './AERO401/Slide26.png'
import AERO401Slide27 from './AERO401/Slide27.png'
import AERO401Slide28 from './AERO401/Slide28.png'
import AERO401Slide29 from './AERO401/Slide29.png'
import AERO401Slide30 from './AERO401/Slide30.png'
import AERO401Slide31 from './AERO401/Slide31.png'
import AERO401Slide32 from './AERO401/Slide32.png'
import AERO401Slide33 from './AERO401/Slide33.png'
import AERO401Slide34 from './AERO401/Slide34.png'
import AERO401Slide35 from './AERO401/Slide35.png'
import AERO401Slide36 from './AERO401/Slide36.png'
import AERO401Slide37 from './AERO401/Slide37.png'




import COSTD_1 from './CostDrivers/Slide1CA.png'
import COSTD_2 from './CostDrivers/Slide2CA.png'
import COSTD_3 from './CostDrivers/Slide3CA.png'
import COSTD_4 from './CostDrivers/Slide4CA.png'
import COSTD_5 from './CostDrivers/Slide5CA.png'
import COSTD_6 from './CostDrivers/Slide6CA.png'
import COSTD_7 from './CostDrivers/Slide7CA.png'
import COSTD_8 from './CostDrivers/Slide8CA.png'
import COSTD_9 from './CostDrivers/Slide9CA.png'
import COSTD_10 from './CostDrivers/Slide10CA.png'
import COSTD_11 from './CostDrivers/Slide11CA.png'
import COSTD_12 from './CostDrivers/Slide12CA.png'
import COSTD_13 from './CostDrivers/Slide13CA.png'
import COSTD_14 from './CostDrivers/Slide14CA.png'
import COSTD_15 from './CostDrivers/Slide15CA.png'
import COSTD_16 from './CostDrivers/Slide16CA.png'
import COSTD_17 from './CostDrivers/Slide17CA.png'
import COSTD_18 from './CostDrivers/Slide18CA.png'
import COSTD_19 from './CostDrivers/Slide19CA.png'
import COSTD_20 from './CostDrivers/Slide20CA.png'
import COSTD_21 from './CostDrivers/Slide21CA.png'
import COSTD_22 from './CostDrivers/Slide22CA.png'
import COSTD_23 from './CostDrivers/Slide23CA.png'
import COSTD_24 from './CostDrivers/Slide24CA.png'
import COSTD_25 from './CostDrivers/Slide25CA.png'
import COSTD_26 from './CostDrivers/Slide26CA.png'
import COSTD_27 from './CostDrivers/Slide27CA.png'



import DODS_0 from './dod-slides/DODSlide0.png'
import DODS_1 from './dod-slides/DODSlide1.png'
import DODS_2 from './dod-slides/DODSlide2.png'
import DODS_3 from './dod-slides/DODSlide3.png'
import DODS_4 from './dod-slides/DODSlide4.png'
import DODS_5 from './dod-slides/DODSlide5.png'
import DODS_6 from './dod-slides/DODSlide6.png'
import DODS_7 from './dod-slides/DODSlide7.png'
import DODS_8 from './dod-slides/DODSlide8.png'
import DODS_9 from './dod-slides/DODSlide9.png'
import DODS_10 from './dod-slides/DODSlide10.png'
import DODS_11 from './dod-slides/DODSlide11.png'
import DODS_12 from './dod-slides/DODSlide12.png'
import DODS_13 from './dod-slides/DODSlide13.png'
import DODS_14 from './dod-slides/DODSlide14.png'
import DODS_15 from './dod-slides/DODSlide15.png'
import DODS_16 from './dod-slides/DODSlide16.png'
import DODS_17 from './dod-slides/DODSlide17.png'
import DODS_18 from './dod-slides/DODSlide18.png'
import DODS_19 from './dod-slides/DODSlide19.png'
import DODS_20 from './dod-slides/DODSlide20.png'
import DODS_21 from './dod-slides/DODSlide21.png'
import DODS_22 from './dod-slides/DODSlide22.png'
import DODS_23 from './dod-slides/DODSlide23.png'
import DODS_24 from './dod-slides/DODSlide24.png'
import DODS_25 from './dod-slides/DODSlide25.png'
import DODS_26 from './dod-slides/DODSlide26.png'
import DODS_27 from './dod-slides/DODSlide27.png'
import DODS_28 from './dod-slides/DODSlide28.png'
import DODS_29 from './dod-slides/DODSlide29.png'
import DODS_30 from './dod-slides/DODSlide30.png'
import DODS_31 from './dod-slides/DODSlide31.png'
import DODS_32 from './dod-slides/DODSlide32.png'
import DODS_33 from './dod-slides/DODSlide33.png'
import DODS_34 from './dod-slides/DODSlide34.png'
import DODS_35 from './dod-slides/DODSlide35.png'
import DODS_36 from './dod-slides/DODSlide36.png'
import DODS_37 from './dod-slides/DODSlide37.png'
import DODS_38 from './dod-slides/DODSlide38.png'
import DODS_39 from './dod-slides/DODSlide39.png'
import DODS_40 from './dod-slides/DODSlide40.png'
import DODS_41 from './dod-slides/DODSlide41.png'
import DODS_42 from './dod-slides/DODSlide42.png'
import DODS_43 from './dod-slides/DODSlide43.png'
import DODS_44 from './dod-slides/DODSlide44.png'
import DODS_45 from './dod-slides/DODSlide45.png'
import DODS_46 from './dod-slides/DODSlide46.png'
import DODS_47 from './dod-slides/DODSlide47.png'
import DODS_48 from './dod-slides/DODSlide48.png'
import DODS_49 from './dod-slides/DODSlide49.png'
import DODS_50 from './dod-slides/DODSlide50.png'
import DODS_51 from './dod-slides/DODSlide51.png'
import DODS_52 from './dod-slides/DODSlide52.png'
import DODS_53 from './dod-slides/DODSlide53.png'
import DODS_54 from './dod-slides/DODSlide54.png'
import DODS_55 from './dod-slides/DODSlide55.png'
import DODS_56 from './dod-slides/DODSlide56.png'
import DODS_57 from './dod-slides/DODSlide57.png'

import Slide1LCCD from './LCCD/Slide1LCCD.png'
import Slide2LCCD from './LCCD/Slide2LCCD.png'


import LC_1 from './lifecycle-cost-learning-module/Slide1LC.png'
import LC_2 from './lifecycle-cost-learning-module/Slide2LC.png'
import LC_3 from './lifecycle-cost-learning-module/Slide3LC.png'
import LC_4 from './lifecycle-cost-learning-module/Slide4LC.png'
import LC_5 from './lifecycle-cost-learning-module/Slide5LC.png'
import LC_6 from './lifecycle-cost-learning-module/Slide6LC.png'
import LC_7 from './lifecycle-cost-learning-module/Slide7LC.png'
import LC_8 from './lifecycle-cost-learning-module/Slide8LC.png'


// import ITSC_1 from './IntroToSpaceFull/Slide1ITSC.png'
// import ITSC_2 from './IntroToSpaceFull/Slide2ITSC.png'
// import ITSC_3 from './IntroToSpaceFull/Slide3ITSC.png'
// import ITSC_4 from './IntroToSpaceFull/Slide4ITSC.png'
// import ITSC_5 from './IntroToSpaceFull/Slide5ITSC.png'
// import ITSC_6 from './IntroToSpaceFull/Slide6ITSC.png'
// import ITSC_7 from './IntroToSpaceFull/Slide7ITSC.png'
// import ITSC_8 from './IntroToSpaceFull/Slide8ITSC.png'
// import ITSC_9 from './IntroToSpaceFull/Slide9ITSC.png'
// import ITSC_10 from './IntroToSpaceFull/Slide10ITSC.png'
// import ITSC_11 from './IntroToSpaceFull/Slide11ITSC.png'
// import ITSC_12 from './IntroToSpaceFull/Slide12ITSC.png'
// import ITSC_13 from './IntroToSpaceFull/Slide13ITSC.png'
// import ITSC_14 from './IntroToSpaceFull/Slide14ITSC.png'
// import ITSC_15 from './IntroToSpaceFull/Slide15ITSC.png'
// import ITSC_16 from './IntroToSpaceFull/Slide16ITSC.png'
// import ITSC_17 from './IntroToSpaceFull/Slide17ITSC.png'
// import ITSC_18 from './IntroToSpaceFull/Slide18ITSC.png'
// import ITSC_19 from './IntroToSpaceFull/Slide19ITSC.png'
// import ITSC_20 from './IntroToSpaceFull/Slide20ITSC.png'
// import ITSC_21 from './IntroToSpaceFull/Slide21ITSC.png'
// import ITSC_22 from './IntroToSpaceFull/Slide22ITSC.png'
// import ITSC_23 from './IntroToSpaceFull/Slide23ITSC.png'
// import ITSC_24 from './IntroToSpaceFull/Slide24ITSC.png'
// import ITSC_25 from './IntroToSpaceFull/Slide25ITSC.png'
// import ITSC_26 from './IntroToSpaceFull/Slide26ITSC.png'
// import ITSC_27 from './IntroToSpaceFull/Slide27ITSC.png'
// import ITSC_28 from './IntroToSpaceFull/Slide28ITSC.png'
// import ITSC_29 from './IntroToSpaceFull/Slide29ITSC.png'
// import ITSC_30 from './IntroToSpaceFull/Slide30ITSC.png'
// import ITSC_31 from './IntroToSpaceFull/Slide31ITSC.png'
// import ITSC_32 from './IntroToSpaceFull/Slide32ITSC.png'
// import ITSC_33 from './IntroToSpaceFull/Slide33ITSC.png'
// import ITSC_34 from './IntroToSpaceFull/Slide34ITSC.png'
// import ITSC_35 from './IntroToSpaceFull/Slide35ITSC.png'
// import ITSC_36 from './IntroToSpaceFull/Slide36ITSC.png'
// import ITSC_37 from './IntroToSpaceFull/Slide37ITSC.png'
// import ITSC_38 from './IntroToSpaceFull/Slide38ITSC.png'
// import ITSC_39 from './IntroToSpaceFull/Slide39ITSC.png'
// import ITSC_40 from './IntroToSpaceFull/Slide40ITSC.png'

import ITSC_1 from './IntroToSpace/Slide1ITSC.png'
import ITSC_2 from './IntroToSpace/Slide2ITSC.png'
import ITSC_3 from './IntroToSpace/Slide3ITSC.png'
import ITSC_4 from './IntroToSpace/Slide4ITSC.png'
import ITSC_5 from './IntroToSpace/Slide5ITSC.png'
import ITSC_6 from './IntroToSpace/Slide6ITSC.png'
import ITSC_7 from './IntroToSpace/Slide7ITSC.png'
import ITSC_8 from './IntroToSpace/Slide8ITSC.png'
import ITSC_9 from './IntroToSpace/Slide9ITSC.png'
import ITSC_10 from './IntroToSpace/Slide10ITSC.png'
import ITSC_11 from './IntroToSpace/Slide11ITSC.png'
import ITSC_12 from './IntroToSpace/Slide12ITSC.png'
import ITSC_13 from './IntroToSpace/Slide13ITSC.png'
import ITSC_14 from './IntroToSpace/Slide14ITSC.png'
import ITSC_15 from './IntroToSpace/Slide15ITSC.png'
import ITSC_16 from './IntroToSpace/Slide16ITSC.png'
import ITSC_17 from './IntroToSpace/Slide17ITSC.png'
import ITSC_18 from './IntroToSpace/Slide18ITSC.png'
import ITSC_19 from './IntroToSpace/Slide19ITSC.png'
import ITSC_20 from './IntroToSpace/Slide20ITSC.png'
import ITSC_21 from './IntroToSpace/Slide21ITSC.png'
import ITSC_22 from './IntroToSpace/Slide22ITSC.png'
import ITSC_23 from './IntroToSpace/Slide23ITSC.png'
import ITSC_24 from './IntroToSpace/Slide24ITSC.png'
import ITSC_25 from './IntroToSpace/Slide25ITSC.png'
import ITSC_26 from './IntroToSpace/Slide26ITSC.png'
import ITSC_27 from './IntroToSpace/Slide27ITSC.png'
import ITSC_28 from './IntroToSpace/Slide28ITSC.png'
import ITSC_29 from './IntroToSpace/Slide29ITSC.png'


// import SEAO_1 from './SpaceEnvAndOrbitsFull/Slide1SEAO.png'
// import SEAO_2 from './SpaceEnvAndOrbitsFull/Slide2SEAO.png'
// import SEAO_3 from './SpaceEnvAndOrbitsFull/Slide3SEAO.png'
// import SEAO_4 from './SpaceEnvAndOrbitsFull/Slide4SEAO.png'
// import SEAO_5 from './SpaceEnvAndOrbitsFull/Slide5SEAO.png'
// import SEAO_6 from './SpaceEnvAndOrbitsFull/Slide6SEAO.png'
// import SEAO_7 from './SpaceEnvAndOrbitsFull/Slide7SEAO.png'
// import SEAO_8 from './SpaceEnvAndOrbitsFull/Slide8SEAO.png'
// import SEAO_9 from './SpaceEnvAndOrbitsFull/Slide9SEAO.png'
// import SEAO_10 from './SpaceEnvAndOrbitsFull/Slide10SEAO.png'
// import SEAO_11 from './SpaceEnvAndOrbitsFull/Slide11SEAO.png'
// import SEAO_12 from './SpaceEnvAndOrbitsFull/Slide12SEAO.png'
// import SEAO_13 from './SpaceEnvAndOrbitsFull/Slide13SEAO.png'
// import SEAO_14 from './SpaceEnvAndOrbitsFull/Slide14SEAO.png'
// import SEAO_15 from './SpaceEnvAndOrbitsFull/Slide15SEAO.png'
// import SEAO_16 from './SpaceEnvAndOrbitsFull/Slide16SEAO.png'
// import SEAO_17 from './SpaceEnvAndOrbitsFull/Slide17SEAO.png'
// import SEAO_18 from './SpaceEnvAndOrbitsFull/Slide18SEAO.png'
// import SEAO_19 from './SpaceEnvAndOrbitsFull/Slide19SEAO.png'
// import SEAO_20 from './SpaceEnvAndOrbitsFull/Slide20SEAO.png'
// import SEAO_21 from './SpaceEnvAndOrbitsFull/Slide21SEAO.png'
// import SEAO_22 from './SpaceEnvAndOrbitsFull/Slide22SEAO.png'
// import SEAO_23 from './SpaceEnvAndOrbitsFull/Slide23SEAO.png'
// import SEAO_24 from './SpaceEnvAndOrbitsFull/Slide24SEAO.png'
// import SEAO_25 from './SpaceEnvAndOrbitsFull/Slide25SEAO.png'
// import SEAO_26 from './SpaceEnvAndOrbitsFull/Slide26SEAO.png'
// import SEAO_27 from './SpaceEnvAndOrbitsFull/Slide27SEAO.png'
// import SEAO_28 from './SpaceEnvAndOrbitsFull/Slide28SEAO.png'
// import SEAO_29 from './SpaceEnvAndOrbitsFull/Slide29SEAO.png'
// import SEAO_30 from './SpaceEnvAndOrbitsFull/Slide30SEAO.png'
// import SEAO_31 from './SpaceEnvAndOrbitsFull/Slide31SEAO.png'
// import SEAO_32 from './SpaceEnvAndOrbitsFull/Slide32SEAO.png'
// import SEAO_33 from './SpaceEnvAndOrbitsFull/Slide33SEAO.png'
// import SEAO_34 from './SpaceEnvAndOrbitsFull/Slide34SEAO.png'
// import SEAO_35 from './SpaceEnvAndOrbitsFull/Slide35SEAO.png'
// import SEAO_36 from './SpaceEnvAndOrbitsFull/Slide36SEAO.png'
// import SEAO_37 from './SpaceEnvAndOrbitsFull/Slide37SEAO.png'
// import SEAO_38 from './SpaceEnvAndOrbitsFull/Slide38SEAO.png'
// import SEAO_39 from './SpaceEnvAndOrbitsFull/Slide39SEAO.png'
// import SEAO_40 from './SpaceEnvAndOrbitsFull/Slide40SEAO.png'
// import SEAO_41 from './SpaceEnvAndOrbitsFull/Slide41SEAO.png'
// import SEAO_42 from './SpaceEnvAndOrbitsFull/Slide42SEAO.png'
// import SEAO_43 from './SpaceEnvAndOrbitsFull/Slide43SEAO.png'
// import SEAO_44 from './SpaceEnvAndOrbitsFull/Slide44SEAO.png'
// import SEAO_45 from './SpaceEnvAndOrbitsFull/Slide45SEAO.png'
// import SEAO_46 from './SpaceEnvAndOrbitsFull/Slide46SEAO.png'
// import SEAO_47 from './SpaceEnvAndOrbitsFull/Slide47SEAO.png'
// import SEAO_48 from './SpaceEnvAndOrbitsFull/Slide48SEAO.png'
// import SEAO_49 from './SpaceEnvAndOrbitsFull/Slide49SEAO.png'


import SEAO_1 from './SpaceEnvAndOrbits/Slide1SEAO.png'
import SEAO_2 from './SpaceEnvAndOrbits/Slide2SEAO.png'
import SEAO_3 from './SpaceEnvAndOrbits/Slide3SEAO.png'
import SEAO_4 from './SpaceEnvAndOrbits/Slide4SEAO.png'
import SEAO_5 from './SpaceEnvAndOrbits/Slide5SEAO.png'
import SEAO_6 from './SpaceEnvAndOrbits/Slide6SEAO.png'
import SEAO_7 from './SpaceEnvAndOrbits/Slide7SEAO.png'
import SEAO_8 from './SpaceEnvAndOrbits/Slide8SEAO.png'
import SEAO_9 from './SpaceEnvAndOrbits/Slide9SEAO.png'
import SEAO_10 from './SpaceEnvAndOrbits/Slide10SEAO.png'
import SEAO_11 from './SpaceEnvAndOrbits/Slide11SEAO.png'
import SEAO_12 from './SpaceEnvAndOrbits/Slide12SEAO.png'
import SEAO_13 from './SpaceEnvAndOrbits/Slide13SEAO.png'
import SEAO_14 from './SpaceEnvAndOrbits/Slide14SEAO.png'
import SEAO_15 from './SpaceEnvAndOrbits/Slide15SEAO.png'
import SEAO_16 from './SpaceEnvAndOrbits/Slide16SEAO.png'
import SEAO_17 from './SpaceEnvAndOrbits/Slide17SEAO.png'
import SEAO_18 from './SpaceEnvAndOrbits/Slide18SEAO.png'
import SEAO_19 from './SpaceEnvAndOrbits/Slide19SEAO.png'
import SEAO_20 from './SpaceEnvAndOrbits/Slide20SEAO.png'
import SEAO_21 from './SpaceEnvAndOrbits/Slide21SEAO.png'
import SEAO_22 from './SpaceEnvAndOrbits/Slide22SEAO.png'
import SEAO_23 from './SpaceEnvAndOrbits/Slide23SEAO.png'
import SEAO_24 from './SpaceEnvAndOrbits/Slide24SEAO.png'
import SEAO_25 from './SpaceEnvAndOrbits/Slide25SEAO.png'
import SEAO_26 from './SpaceEnvAndOrbits/Slide26SEAO.png'
import SEAO_27 from './SpaceEnvAndOrbits/Slide27SEAO.png'
import SEAO_28 from './SpaceEnvAndOrbits/Slide28SEAO.png'
import SEAO_29 from './SpaceEnvAndOrbits/Slide29SEAO.png'
import SEAO_30 from './SpaceEnvAndOrbits/Slide30SEAO.png'
import SEAO_31 from './SpaceEnvAndOrbits/Slide31SEAO.png'
import SEAO_32 from './SpaceEnvAndOrbits/Slide32SEAO.png'


// MODULE 1: Space mission overview



import ISC_SMO_1 from './ISC-SpaceMissionOverview/Slide1.png'
import ISC_SMO_2 from './ISC-SpaceMissionOverview/Slide2.png'
import ISC_SMO_3 from './ISC-SpaceMissionOverview/Slide3.png'
import ISC_SMO_4 from './ISC-SpaceMissionOverview/Slide4.png'
import ISC_SMO_5 from './ISC-SpaceMissionOverview/Slide5.png'
import ISC_SMO_6 from './ISC-SpaceMissionOverview/Slide6.png'
import ISC_SMO_7 from './ISC-SpaceMissionOverview/Slide7.png'
import ISC_SMO_8 from './ISC-SpaceMissionOverview/Slide8.png'
import ISC_SMO_9 from './ISC-SpaceMissionOverview/Slide9.png'
import ISC_SMO_10 from './ISC-SpaceMissionOverview/Slide10.png'
import ISC_SMO_11 from './ISC-SpaceMissionOverview/Slide11.png'
import ISC_SMO_12 from './ISC-SpaceMissionOverview/Slide12.png'
import ISC_SMO_13 from './ISC-SpaceMissionOverview/Slide13.png'
import ISC_SMO_14 from './ISC-SpaceMissionOverview/Slide14.png'
import ISC_SMO_15 from './ISC-SpaceMissionOverview/Slide15.png'
import ISC_SMO_16 from './ISC-SpaceMissionOverview/Slide16.png'
import ISC_SMO_17 from './ISC-SpaceMissionOverview/Slide17.png'
import ISC_SMO_18 from './ISC-SpaceMissionOverview/Slide18.png'
import ISC_SMO_19 from './ISC-SpaceMissionOverview/Slide19.png'
import ISC_SMO_20 from './ISC-SpaceMissionOverview/Slide20.png'
import ISC_SMO_21 from './ISC-SpaceMissionOverview/Slide21.png'
import ISC_SMO_22 from './ISC-SpaceMissionOverview/Slide22.png'
import ISC_SMO_23 from './ISC-SpaceMissionOverview/Slide23.png'
import ISC_SMO_24 from './ISC-SpaceMissionOverview/Slide24.png'
import ISC_SMO_25 from './ISC-SpaceMissionOverview/Slide25.png'
import ISC_SMO_26 from './ISC-SpaceMissionOverview/Slide26.png'
import ISC_SMO_27 from './ISC-SpaceMissionOverview/Slide27.png'
import ISC_SMO_28 from './ISC-SpaceMissionOverview/Slide28.png'
import ISC_SMO_29 from './ISC-SpaceMissionOverview/Slide29.png'
import ISC_SMO_30 from './ISC-SpaceMissionOverview/Slide30.png'


// MODULE 2: Space env and orbits

import ISC_SEO_1 from './ISC-SpaceEnvAndOrbs/Slide1.png'
import ISC_SEO_2 from './ISC-SpaceEnvAndOrbs/Slide2.png'
import ISC_SEO_3 from './ISC-SpaceEnvAndOrbs/Slide3.png'
import ISC_SEO_4 from './ISC-SpaceEnvAndOrbs/Slide4.png'
import ISC_SEO_5 from './ISC-SpaceEnvAndOrbs/Slide5.png'
import ISC_SEO_6 from './ISC-SpaceEnvAndOrbs/Slide6.png'
import ISC_SEO_7 from './ISC-SpaceEnvAndOrbs/Slide7.png'
import ISC_SEO_8 from './ISC-SpaceEnvAndOrbs/Slide8.png'
import ISC_SEO_9 from './ISC-SpaceEnvAndOrbs/Slide9.png'
import ISC_SEO_10 from './ISC-SpaceEnvAndOrbs/Slide10.png'
import ISC_SEO_11 from './ISC-SpaceEnvAndOrbs/Slide11.png'
import ISC_SEO_12 from './ISC-SpaceEnvAndOrbs/Slide12.png'
import ISC_SEO_13 from './ISC-SpaceEnvAndOrbs/Slide13.png'
import ISC_SEO_14 from './ISC-SpaceEnvAndOrbs/Slide14.png'
import ISC_SEO_15 from './ISC-SpaceEnvAndOrbs/Slide15.png'
import ISC_SEO_16 from './ISC-SpaceEnvAndOrbs/Slide16.png'
import ISC_SEO_17 from './ISC-SpaceEnvAndOrbs/Slide17.png'
import ISC_SEO_18 from './ISC-SpaceEnvAndOrbs/Slide18.png'
import ISC_SEO_19 from './ISC-SpaceEnvAndOrbs/Slide19.png'
import ISC_SEO_20 from './ISC-SpaceEnvAndOrbs/Slide20.png'
import ISC_SEO_21 from './ISC-SpaceEnvAndOrbs/Slide21.png'
import ISC_SEO_22 from './ISC-SpaceEnvAndOrbs/Slide22.png'
import ISC_SEO_23 from './ISC-SpaceEnvAndOrbs/Slide23.png'
import ISC_SEO_24 from './ISC-SpaceEnvAndOrbs/Slide24.png'
import ISC_SEO_25 from './ISC-SpaceEnvAndOrbs/Slide25.png'
import ISC_SEO_26 from './ISC-SpaceEnvAndOrbs/Slide26.png'
import ISC_SEO_27 from './ISC-SpaceEnvAndOrbs/Slide27.png'
import ISC_SEO_28 from './ISC-SpaceEnvAndOrbs/Slide28.png'
import ISC_SEO_29 from './ISC-SpaceEnvAndOrbs/Slide29.png'
import ISC_SEO_30 from './ISC-SpaceEnvAndOrbs/Slide30.png'
import ISC_SEO_31 from './ISC-SpaceEnvAndOrbs/Slide31.png'
import ISC_SEO_32 from './ISC-SpaceEnvAndOrbs/Slide32.png'
import ISC_SEO_33 from './ISC-SpaceEnvAndOrbs/Slide33.png'
import ISC_SEO_34 from './ISC-SpaceEnvAndOrbs/Slide34.png'
import ISC_SEO_35 from './ISC-SpaceEnvAndOrbs/Slide35.png'
import ISC_SEO_36 from './ISC-SpaceEnvAndOrbs/Slide36.png'
import ISC_SEO_37 from './ISC-SpaceEnvAndOrbs/Slide37.png'
import ISC_SEO_38 from './ISC-SpaceEnvAndOrbs/Slide38.png'
import ISC_SEO_39 from './ISC-SpaceEnvAndOrbs/Slide39.png'
import ISC_SEO_40 from './ISC-SpaceEnvAndOrbs/Slide40.png'
import ISC_SEO_41 from './ISC-SpaceEnvAndOrbs/Slide41.png'
import ISC_SEO_42 from './ISC-SpaceEnvAndOrbs/Slide42.png'
import ISC_SEO_43 from './ISC-SpaceEnvAndOrbs/Slide43.png'
import ISC_SEO_44 from './ISC-SpaceEnvAndOrbs/Slide44.png'
import ISC_SEO_45 from './ISC-SpaceEnvAndOrbs/Slide45.png'
import ISC_SEO_46 from './ISC-SpaceEnvAndOrbs/Slide46.png'
import ISC_SEO_47 from './ISC-SpaceEnvAndOrbs/Slide47.png'
import ISC_SEO_48 from './ISC-SpaceEnvAndOrbs/Slide48.png'
import ISC_SEO_49 from './ISC-SpaceEnvAndOrbs/Slide49.png'
import ISC_SEO_50 from './ISC-SpaceEnvAndOrbs/Slide50.png'




// MODULE 3: Spacecraft tech and arch

import ISC_STA_1 from './ISC-SpacecraftTechAndArch/Slide1.png'
import ISC_STA_2 from './ISC-SpacecraftTechAndArch/Slide2.png'
import ISC_STA_3 from './ISC-SpacecraftTechAndArch/Slide3.png'
import ISC_STA_4 from './ISC-SpacecraftTechAndArch/Slide4.png'
import ISC_STA_5 from './ISC-SpacecraftTechAndArch/Slide5.png'
import ISC_STA_6 from './ISC-SpacecraftTechAndArch/Slide6.png'
import ISC_STA_7 from './ISC-SpacecraftTechAndArch/Slide7.png'
import ISC_STA_8 from './ISC-SpacecraftTechAndArch/Slide8.png'
import ISC_STA_9 from './ISC-SpacecraftTechAndArch/Slide9.png'
import ISC_STA_10 from './ISC-SpacecraftTechAndArch/Slide10.png'
import ISC_STA_11 from './ISC-SpacecraftTechAndArch/Slide11.png'
import ISC_STA_12 from './ISC-SpacecraftTechAndArch/Slide12.png'
import ISC_STA_13 from './ISC-SpacecraftTechAndArch/Slide13.png'
import ISC_STA_14 from './ISC-SpacecraftTechAndArch/Slide14.png'
import ISC_STA_15 from './ISC-SpacecraftTechAndArch/Slide15.png'
import ISC_STA_16 from './ISC-SpacecraftTechAndArch/Slide16.png'
import ISC_STA_17 from './ISC-SpacecraftTechAndArch/Slide17.png'
import ISC_STA_18 from './ISC-SpacecraftTechAndArch/Slide18.png'
import ISC_STA_19 from './ISC-SpacecraftTechAndArch/Slide19.png'
import ISC_STA_20 from './ISC-SpacecraftTechAndArch/Slide20.png'
import ISC_STA_21 from './ISC-SpacecraftTechAndArch/Slide21.png'
import ISC_STA_22 from './ISC-SpacecraftTechAndArch/Slide22.png'
import ISC_STA_23 from './ISC-SpacecraftTechAndArch/Slide23.png'
import ISC_STA_24 from './ISC-SpacecraftTechAndArch/Slide24.png'
import ISC_STA_25 from './ISC-SpacecraftTechAndArch/Slide25.png'
import ISC_STA_26 from './ISC-SpacecraftTechAndArch/Slide26.png'
import ISC_STA_27 from './ISC-SpacecraftTechAndArch/Slide27.png'
import ISC_STA_28 from './ISC-SpacecraftTechAndArch/Slide28.png'
import ISC_STA_29 from './ISC-SpacecraftTechAndArch/Slide29.png'
import ISC_STA_30 from './ISC-SpacecraftTechAndArch/Slide30.png'
import ISC_STA_31 from './ISC-SpacecraftTechAndArch/Slide31.png'
import ISC_STA_32 from './ISC-SpacecraftTechAndArch/Slide32.png'
import ISC_STA_33 from './ISC-SpacecraftTechAndArch/Slide33.png'
import ISC_STA_34 from './ISC-SpacecraftTechAndArch/Slide34.png'
import ISC_STA_35 from './ISC-SpacecraftTechAndArch/Slide35.png'
import ISC_STA_36 from './ISC-SpacecraftTechAndArch/Slide36.png'
import ISC_STA_37 from './ISC-SpacecraftTechAndArch/Slide37.png'
import ISC_STA_38 from './ISC-SpacecraftTechAndArch/Slide38.png'
import ISC_STA_39 from './ISC-SpacecraftTechAndArch/Slide39.png'
import ISC_STA_40 from './ISC-SpacecraftTechAndArch/Slide40.png'
import ISC_STA_41 from './ISC-SpacecraftTechAndArch/Slide41.png'
import ISC_STA_42 from './ISC-SpacecraftTechAndArch/Slide42.png'
import ISC_STA_43 from './ISC-SpacecraftTechAndArch/Slide43.png'
import ISC_STA_44 from './ISC-SpacecraftTechAndArch/Slide44.png'
import ISC_STA_45 from './ISC-SpacecraftTechAndArch/Slide45.png'
import ISC_STA_46 from './ISC-SpacecraftTechAndArch/Slide46.png'
import ISC_STA_47 from './ISC-SpacecraftTechAndArch/Slide47.png'
import ISC_STA_48 from './ISC-SpacecraftTechAndArch/Slide48.png'
import ISC_STA_49 from './ISC-SpacecraftTechAndArch/Slide49.png'
import ISC_STA_50 from './ISC-SpacecraftTechAndArch/Slide50.png'
import ISC_STA_51 from './ISC-SpacecraftTechAndArch/Slide51.png'
import ISC_STA_52 from './ISC-SpacecraftTechAndArch/Slide52.png'
import ISC_STA_53 from './ISC-SpacecraftTechAndArch/Slide53.png'
import ISC_STA_54 from './ISC-SpacecraftTechAndArch/Slide54.png'
import ISC_STA_55 from './ISC-SpacecraftTechAndArch/Slide55.png'
import ISC_STA_56 from './ISC-SpacecraftTechAndArch/Slide56.png'
import ISC_STA_57 from './ISC-SpacecraftTechAndArch/Slide57.png'
import ISC_STA_58 from './ISC-SpacecraftTechAndArch/Slide58.png'
import ISC_STA_59 from './ISC-SpacecraftTechAndArch/Slide59.png'
import ISC_STA_60 from './ISC-SpacecraftTechAndArch/Slide60.png'
import ISC_STA_61 from './ISC-SpacecraftTechAndArch/Slide61.png'
import ISC_STA_62 from './ISC-SpacecraftTechAndArch/Slide62.png'
import ISC_STA_63 from './ISC-SpacecraftTechAndArch/Slide63.png'
import ISC_STA_64 from './ISC-SpacecraftTechAndArch/Slide64.png'
import ISC_STA_65 from './ISC-SpacecraftTechAndArch/Slide65.png'
import ISC_STA_66 from './ISC-SpacecraftTechAndArch/Slide66.png'
import ISC_STA_67 from './ISC-SpacecraftTechAndArch/Slide67.png'
import ISC_STA_68 from './ISC-SpacecraftTechAndArch/Slide68.png'
import ISC_STA_69 from './ISC-SpacecraftTechAndArch/Slide69.png'
import ISC_STA_70 from './ISC-SpacecraftTechAndArch/Slide70.png'
import ISC_STA_71 from './ISC-SpacecraftTechAndArch/Slide71.png'
import ISC_STA_72 from './ISC-SpacecraftTechAndArch/Slide72.png'
import ISC_STA_73 from './ISC-SpacecraftTechAndArch/Slide73.png'
import ISC_STA_74 from './ISC-SpacecraftTechAndArch/Slide74.png'
import ISC_STA_75 from './ISC-SpacecraftTechAndArch/Slide75.png'
import ISC_STA_76 from './ISC-SpacecraftTechAndArch/Slide76.png'
import ISC_STA_77 from './ISC-SpacecraftTechAndArch/Slide77.png'
import ISC_STA_78 from './ISC-SpacecraftTechAndArch/Slide78.png'
import ISC_STA_79 from './ISC-SpacecraftTechAndArch/Slide79.png'
import ISC_STA_80 from './ISC-SpacecraftTechAndArch/Slide80.png'
import ISC_STA_81 from './ISC-SpacecraftTechAndArch/Slide81.png'
import ISC_STA_82 from './ISC-SpacecraftTechAndArch/Slide82.png'
import ISC_STA_83 from './ISC-SpacecraftTechAndArch/Slide83.png'
import ISC_STA_84 from './ISC-SpacecraftTechAndArch/Slide84.png'
import ISC_STA_85 from './ISC-SpacecraftTechAndArch/Slide85.png'
import ISC_STA_86 from './ISC-SpacecraftTechAndArch/Slide86.png'
import ISC_STA_87 from './ISC-SpacecraftTechAndArch/Slide87.png'
import ISC_STA_88 from './ISC-SpacecraftTechAndArch/Slide88.png'
import ISC_STA_89 from './ISC-SpacecraftTechAndArch/Slide89.png'
import ISC_STA_90 from './ISC-SpacecraftTechAndArch/Slide90.png'
import ISC_STA_91 from './ISC-SpacecraftTechAndArch/Slide91.png'
import ISC_STA_92 from './ISC-SpacecraftTechAndArch/Slide92.png'
import ISC_STA_93 from './ISC-SpacecraftTechAndArch/Slide93.png'
import ISC_STA_94 from './ISC-SpacecraftTechAndArch/Slide94.png'
import ISC_STA_95 from './ISC-SpacecraftTechAndArch/Slide95.png'
import ISC_STA_96 from './ISC-SpacecraftTechAndArch/Slide96.png'
import ISC_STA_97 from './ISC-SpacecraftTechAndArch/Slide97.png'
import ISC_STA_98 from './ISC-SpacecraftTechAndArch/Slide98.png'
import ISC_STA_99 from './ISC-SpacecraftTechAndArch/Slide99.png'
import ISC_STA_100 from './ISC-SpacecraftTechAndArch/Slide100.png'
import ISC_STA_101 from './ISC-SpacecraftTechAndArch/Slide101.png'
import ISC_STA_102 from './ISC-SpacecraftTechAndArch/Slide102.png'
import ISC_STA_103 from './ISC-SpacecraftTechAndArch/Slide103.png'
import ISC_STA_104 from './ISC-SpacecraftTechAndArch/Slide104.png'
import ISC_STA_105 from './ISC-SpacecraftTechAndArch/Slide105.png'
import ISC_STA_106 from './ISC-SpacecraftTechAndArch/Slide106.png'
import ISC_STA_107 from './ISC-SpacecraftTechAndArch/Slide107.png'
import ISC_STA_108 from './ISC-SpacecraftTechAndArch/Slide108.png';
import ISC_STA_109 from './ISC-SpacecraftTechAndArch/Slide109.png';
import ISC_STA_110 from './ISC-SpacecraftTechAndArch/Slide110.png';
import ISC_STA_111 from './ISC-SpacecraftTechAndArch/Slide111.png';
import ISC_STA_112 from './ISC-SpacecraftTechAndArch/Slide112.png';
import ISC_STA_113 from './ISC-SpacecraftTechAndArch/Slide113.png';
import ISC_STA_114 from './ISC-SpacecraftTechAndArch/Slide114.png';
import ISC_STA_115 from './ISC-SpacecraftTechAndArch/Slide115.png';
import ISC_STA_116 from './ISC-SpacecraftTechAndArch/Slide116.png';
import ISC_STA_117 from './ISC-SpacecraftTechAndArch/Slide117.png';
import ISC_STA_118 from './ISC-SpacecraftTechAndArch/Slide118.png';
import ISC_STA_119 from './ISC-SpacecraftTechAndArch/Slide119.png';
import ISC_STA_120 from './ISC-SpacecraftTechAndArch/Slide120.png';
import ISC_STA_121 from './ISC-SpacecraftTechAndArch/Slide121.png';
import ISC_STA_122 from './ISC-SpacecraftTechAndArch/Slide122.png';
import ISC_STA_123 from './ISC-SpacecraftTechAndArch/Slide123.png';
import ISC_STA_124 from './ISC-SpacecraftTechAndArch/Slide124.png';
import ISC_STA_125 from './ISC-SpacecraftTechAndArch/Slide125.png';
import ISC_STA_126 from './ISC-SpacecraftTechAndArch/Slide126.png';
import ISC_STA_127 from './ISC-SpacecraftTechAndArch/Slide127.png';
import ISC_STA_128 from './ISC-SpacecraftTechAndArch/Slide128.png';
import ISC_STA_129 from './ISC-SpacecraftTechAndArch/Slide129.png';




// MODULE 4: Sat remote sending payloads

import ISC_SSP_1 from './ISC-SatScientificPayload/Slide1.png'
import ISC_SSP_2 from './ISC-SatScientificPayload/Slide2.png'
import ISC_SSP_3 from './ISC-SatScientificPayload/Slide3.png'
import ISC_SSP_4 from './ISC-SatScientificPayload/Slide4.png'
import ISC_SSP_5 from './ISC-SatScientificPayload/Slide5.png'
import ISC_SSP_6 from './ISC-SatScientificPayload/Slide6.png'
import ISC_SSP_7 from './ISC-SatScientificPayload/Slide7.png'
import ISC_SSP_8 from './ISC-SatScientificPayload/Slide8.png'
import ISC_SSP_9 from './ISC-SatScientificPayload/Slide9.png'
import ISC_SSP_10 from './ISC-SatScientificPayload/Slide10.png'
import ISC_SSP_11 from './ISC-SatScientificPayload/Slide11.png'
import ISC_SSP_12 from './ISC-SatScientificPayload/Slide12.png'
import ISC_SSP_13 from './ISC-SatScientificPayload/Slide13.png'
import ISC_SSP_14 from './ISC-SatScientificPayload/Slide14.png'
import ISC_SSP_15 from './ISC-SatScientificPayload/Slide15.png'
import ISC_SSP_16 from './ISC-SatScientificPayload/Slide16.png'
import ISC_SSP_17 from './ISC-SatScientificPayload/Slide17.png'
import ISC_SSP_18 from './ISC-SatScientificPayload/Slide18.png'
import ISC_SSP_19 from './ISC-SatScientificPayload/Slide19.png'
import ISC_SSP_20 from './ISC-SatScientificPayload/Slide20.png'
import ISC_SSP_21 from './ISC-SatScientificPayload/Slide21.png'
import ISC_SSP_22 from './ISC-SatScientificPayload/Slide22.png'
import ISC_SSP_23 from './ISC-SatScientificPayload/Slide23.png'
import ISC_SSP_24 from './ISC-SatScientificPayload/Slide24.png'
import ISC_SSP_25 from './ISC-SatScientificPayload/Slide25.png'
import ISC_SSP_26 from './ISC-SatScientificPayload/Slide26.png'
import ISC_SSP_27 from './ISC-SatScientificPayload/Slide27.png'
import ISC_SSP_28 from './ISC-SatScientificPayload/Slide28.png'
import ISC_SSP_29 from './ISC-SatScientificPayload/Slide29.png'
import ISC_SSP_30 from './ISC-SatScientificPayload/Slide30.png'
import ISC_SSP_31 from './ISC-SatScientificPayload/Slide31.png'
import ISC_SSP_32 from './ISC-SatScientificPayload/Slide32.png'
import ISC_SSP_33 from './ISC-SatScientificPayload/Slide33.png'
import ISC_SSP_34 from './ISC-SatScientificPayload/Slide34.png'
import ISC_SSP_35 from './ISC-SatScientificPayload/Slide35.png'
import ISC_SSP_36 from './ISC-SatScientificPayload/Slide36.png'
import ISC_SSP_37 from './ISC-SatScientificPayload/Slide37.png'
import ISC_SSP_38 from './ISC-SatScientificPayload/Slide38.png'
import ISC_SSP_39 from './ISC-SatScientificPayload/Slide39.png'
import ISC_SSP_40 from './ISC-SatScientificPayload/Slide40.png'
import ISC_SSP_41 from './ISC-SatScientificPayload/Slide41.png'
import ISC_SSP_42 from './ISC-SatScientificPayload/Slide42.png'
import ISC_SSP_43 from './ISC-SatScientificPayload/Slide43.png'
import ISC_SSP_44 from './ISC-SatScientificPayload/Slide44.png'
import ISC_SSP_45 from './ISC-SatScientificPayload/Slide45.png'
import ISC_SSP_46 from './ISC-SatScientificPayload/Slide46.png'
import ISC_SSP_47 from './ISC-SatScientificPayload/Slide47.png'
import ISC_SSP_48 from './ISC-SatScientificPayload/Slide48.png'
import ISC_SSP_49 from './ISC-SatScientificPayload/Slide49.png'
import ISC_SSP_50 from './ISC-SatScientificPayload/Slide50.png'
import ISC_SSP_51 from './ISC-SatScientificPayload/Slide51.png'
import ISC_SSP_52 from './ISC-SatScientificPayload/Slide52.png'
import ISC_SSP_53 from './ISC-SatScientificPayload/Slide53.png'
import ISC_SSP_54 from './ISC-SatScientificPayload/Slide54.png'
import ISC_SSP_55 from './ISC-SatScientificPayload/Slide55.png'
import ISC_SSP_56 from './ISC-SatScientificPayload/Slide56.png'
import ISC_SSP_57 from './ISC-SatScientificPayload/Slide57.png'
import ISC_SSP_58 from './ISC-SatScientificPayload/Slide58.png'
import ISC_SSP_59 from './ISC-SatScientificPayload/Slide59.png'
import ISC_SSP_60 from './ISC-SatScientificPayload/Slide60.png'
import ISC_SSP_61 from './ISC-SatScientificPayload/Slide61.png'
import ISC_SSP_62 from './ISC-SatScientificPayload/Slide62.png'
import ISC_SSP_63 from './ISC-SatScientificPayload/Slide63.png'
import ISC_SSP_64 from './ISC-SatScientificPayload/Slide64.png'
import ISC_SSP_65 from './ISC-SatScientificPayload/Slide65.png'
import ISC_SSP_66 from './ISC-SatScientificPayload/Slide66.png'
import ISC_SSP_67 from './ISC-SatScientificPayload/Slide67.png'
import ISC_SSP_68 from './ISC-SatScientificPayload/Slide68.png'
import ISC_SSP_69 from './ISC-SatScientificPayload/Slide69.png'
import ISC_SSP_70 from './ISC-SatScientificPayload/Slide70.png'
import ISC_SSP_71 from './ISC-SatScientificPayload/Slide71.png'
import ISC_SSP_72 from './ISC-SatScientificPayload/Slide72.png'
import ISC_SSP_73 from './ISC-SatScientificPayload/Slide73.png'
import ISC_SSP_74 from './ISC-SatScientificPayload/Slide74.png'
import ISC_SSP_75 from './ISC-SatScientificPayload/Slide75.png'
import ISC_SSP_76 from './ISC-SatScientificPayload/Slide76.png'
import ISC_SSP_77 from './ISC-SatScientificPayload/Slide77.png'
import ISC_SSP_78 from './ISC-SatScientificPayload/Slide78.png'
import ISC_SSP_79 from './ISC-SatScientificPayload/Slide79.png'
import ISC_SSP_80 from './ISC-SatScientificPayload/Slide80.png'
import ISC_SSP_81 from './ISC-SatScientificPayload/Slide81.png'
import ISC_SSP_82 from './ISC-SatScientificPayload/Slide82.png'
import ISC_SSP_83 from './ISC-SatScientificPayload/Slide83.png'
import ISC_SSP_84 from './ISC-SatScientificPayload/Slide84.png'
import ISC_SSP_85 from './ISC-SatScientificPayload/Slide85.png'
import ISC_SSP_86 from './ISC-SatScientificPayload/Slide86.png'
import ISC_SSP_87 from './ISC-SatScientificPayload/Slide87.png'
import ISC_SSP_88 from './ISC-SatScientificPayload/Slide88.png'
import ISC_SSP_89 from './ISC-SatScientificPayload/Slide89.png'
import ISC_SSP_90 from './ISC-SatScientificPayload/Slide90.png'
import ISC_SSP_91 from './ISC-SatScientificPayload/Slide91.png'
import ISC_SSP_92 from './ISC-SatScientificPayload/Slide92.png'
import ISC_SSP_93 from './ISC-SatScientificPayload/Slide93.png'
import ISC_SSP_94 from './ISC-SatScientificPayload/Slide94.png'
import ISC_SSP_95 from './ISC-SatScientificPayload/Slide95.png'
import ISC_SSP_96 from './ISC-SatScientificPayload/Slide96.png'
import ISC_SSP_97 from './ISC-SatScientificPayload/Slide97.png'
import ISC_SSP_98 from './ISC-SatScientificPayload/Slide98.png'
import ISC_SSP_99 from './ISC-SatScientificPayload/Slide99.png'
import ISC_SSP_100 from './ISC-SatScientificPayload/Slide100.png'
import ISC_SSP_101 from './ISC-SatScientificPayload/Slide101.png'
import ISC_SSP_102 from './ISC-SatScientificPayload/Slide102.png'
import ISC_SSP_103 from './ISC-SatScientificPayload/Slide103.png'







export function get_slide_src(src){


    // MODULE 4: Sat remote sending payloads
    if (src === 'ISC_SSP_1') {
        return ISC_SSP_1;
    } else if (src === 'ISC_SSP_2') {
        return ISC_SSP_2;
    } else if (src === 'ISC_SSP_3') {
        return ISC_SSP_3;
    } else if (src === 'ISC_SSP_4') {
        return ISC_SSP_4;
    } else if (src === 'ISC_SSP_5') {
        return ISC_SSP_5;
    } else if (src === 'ISC_SSP_6') {
        return ISC_SSP_6;
    } else if (src === 'ISC_SSP_7') {
        return ISC_SSP_7;
    } else if (src === 'ISC_SSP_8') {
        return ISC_SSP_8;
    } else if (src === 'ISC_SSP_9') {
        return ISC_SSP_9;
    } else if (src === 'ISC_SSP_10') {
        return ISC_SSP_10;
    } else if (src === 'ISC_SSP_11') {
        return ISC_SSP_11;
    } else if (src === 'ISC_SSP_12') {
        return ISC_SSP_12;
    } else if (src === 'ISC_SSP_13') {
        return ISC_SSP_13;
    } else if (src === 'ISC_SSP_14') {
        return ISC_SSP_14;
    } else if (src === 'ISC_SSP_15') {
        return ISC_SSP_15;
    } else if (src === 'ISC_SSP_16') {
        return ISC_SSP_16;
    } else if (src === 'ISC_SSP_17') {
        return ISC_SSP_17;
    } else if (src === 'ISC_SSP_18') {
        return ISC_SSP_18;
    } else if (src === 'ISC_SSP_19') {
        return ISC_SSP_19;
    } else if (src === 'ISC_SSP_20') {
        return ISC_SSP_20;
    } else if (src === 'ISC_SSP_21') {
        return ISC_SSP_21;
    } else if (src === 'ISC_SSP_22') {
        return ISC_SSP_22;
    } else if (src === 'ISC_SSP_23') {
        return ISC_SSP_23;
    } else if (src === 'ISC_SSP_24') {
        return ISC_SSP_24;
    } else if (src === 'ISC_SSP_25') {
        return ISC_SSP_25;
    } else if (src === 'ISC_SSP_26') {
        return ISC_SSP_26;
    } else if (src === 'ISC_SSP_27') {
        return ISC_SSP_27;
    } else if (src === 'ISC_SSP_28') {
        return ISC_SSP_28;
    } else if (src === 'ISC_SSP_29') {
        return ISC_SSP_29;
    } else if (src === 'ISC_SSP_30') {
        return ISC_SSP_30;
    } else if (src === 'ISC_SSP_31') {
        return ISC_SSP_31;
    } else if (src === 'ISC_SSP_32') {
        return ISC_SSP_32;
    } else if (src === 'ISC_SSP_33') {
        return ISC_SSP_33;
    } else if (src === 'ISC_SSP_34') {
        return ISC_SSP_34;
    } else if (src === 'ISC_SSP_35') {
        return ISC_SSP_35;
    } else if (src === 'ISC_SSP_36') {
        return ISC_SSP_36;
    } else if (src === 'ISC_SSP_37') {
        return ISC_SSP_37;
    } else if (src === 'ISC_SSP_38') {
        return ISC_SSP_38;
    } else if (src === 'ISC_SSP_39') {
        return ISC_SSP_39;
    } else if (src === 'ISC_SSP_40') {
        return ISC_SSP_40;
    } else if (src === 'ISC_SSP_41') {
        return ISC_SSP_41;
    } else if (src === 'ISC_SSP_42') {
        return ISC_SSP_42;
    } else if (src === 'ISC_SSP_43') {
        return ISC_SSP_43;
    } else if (src === 'ISC_SSP_44') {
        return ISC_SSP_44;
    } else if (src === 'ISC_SSP_45') {
        return ISC_SSP_45;
    } else if (src === 'ISC_SSP_46') {
        return ISC_SSP_46;
    } else if (src === 'ISC_SSP_47') {
        return ISC_SSP_47;
    } else if (src === 'ISC_SSP_48') {
        return ISC_SSP_48;
    } else if (src === 'ISC_SSP_49') {
        return ISC_SSP_49;
    } else if (src === 'ISC_SSP_50') {
        return ISC_SSP_50;
    } else if (src === 'ISC_SSP_51') {
        return ISC_SSP_51;
    } else if (src === 'ISC_SSP_52') {
        return ISC_SSP_52;
    } else if (src === 'ISC_SSP_53') {
        return ISC_SSP_53;
    } else if (src === 'ISC_SSP_54') {
        return ISC_SSP_54;
    } else if (src === 'ISC_SSP_55') {
        return ISC_SSP_55;
    } else if (src === 'ISC_SSP_56') {
        return ISC_SSP_56;
    } else if (src === 'ISC_SSP_57') {
        return ISC_SSP_57;
    } else if (src === 'ISC_SSP_58') {
        return ISC_SSP_58;
    } else if (src === 'ISC_SSP_59') {
        return ISC_SSP_59;
    } else if (src === 'ISC_SSP_60') {
        return ISC_SSP_60;
    } else if (src === 'ISC_SSP_61') {
        return ISC_SSP_61;
    } else if (src === 'ISC_SSP_62') {
        return ISC_SSP_62;
    } else if (src === 'ISC_SSP_63') {
        return ISC_SSP_63;
    } else if (src === 'ISC_SSP_64') {
        return ISC_SSP_64;
    } else if (src === 'ISC_SSP_65') {
        return ISC_SSP_65;
    } else if (src === 'ISC_SSP_66') {
        return ISC_SSP_66;
    } else if (src === 'ISC_SSP_67') {
        return ISC_SSP_67;
    } else if (src === 'ISC_SSP_68') {
        return ISC_SSP_68;
    } else if (src === 'ISC_SSP_69') {
        return ISC_SSP_69;
    } else if (src === 'ISC_SSP_70') {
        return ISC_SSP_70;
    } else if (src === 'ISC_SSP_71') {
        return ISC_SSP_71;
    } else if (src === 'ISC_SSP_72') {
        return ISC_SSP_72;
    } else if (src === 'ISC_SSP_73') {
        return ISC_SSP_73;
    } else if (src === 'ISC_SSP_74') {
        return ISC_SSP_74;
    } else if (src === 'ISC_SSP_75') {
        return ISC_SSP_75;
    } else if (src === 'ISC_SSP_76') {
        return ISC_SSP_76;
    } else if (src === 'ISC_SSP_77') {
        return ISC_SSP_77;
    } else if (src === 'ISC_SSP_78') {
        return ISC_SSP_78;
    } else if (src === 'ISC_SSP_79') {
        return ISC_SSP_79;
    } else if (src === 'ISC_SSP_80') {
        return ISC_SSP_80;
    } else if (src === 'ISC_SSP_81') {
        return ISC_SSP_81;
    } else if (src === 'ISC_SSP_82') {
        return ISC_SSP_82;
    } else if (src === 'ISC_SSP_83') {
        return ISC_SSP_83;
    } else if (src === 'ISC_SSP_84') {
        return ISC_SSP_84;
    } else if (src === 'ISC_SSP_85') {
        return ISC_SSP_85;
    } else if (src === 'ISC_SSP_86') {
        return ISC_SSP_86;
    } else if (src === 'ISC_SSP_87') {
        return ISC_SSP_87;
    } else if (src === 'ISC_SSP_88') {
        return ISC_SSP_88;
    } else if (src === 'ISC_SSP_89') {
        return ISC_SSP_89;
    } else if (src === 'ISC_SSP_90') {
        return ISC_SSP_90;
    } else if (src === 'ISC_SSP_91') {
        return ISC_SSP_91;
    } else if (src === 'ISC_SSP_92') {
        return ISC_SSP_92;
    } else if (src === 'ISC_SSP_93') {
        return ISC_SSP_93;
    } else if (src === 'ISC_SSP_94') {
        return ISC_SSP_94;
    } else if (src === 'ISC_SSP_95') {
        return ISC_SSP_95;
    } else if (src === 'ISC_SSP_96') {
        return ISC_SSP_96;
    } else if (src === 'ISC_SSP_97') {
        return ISC_SSP_97;
    } else if (src === 'ISC_SSP_98') {
        return ISC_SSP_98;
    } else if (src === 'ISC_SSP_99') {
        return ISC_SSP_99;
    } else if (src === 'ISC_SSP_100') {
        return ISC_SSP_100;
    } else if (src === 'ISC_SSP_101') {
        return ISC_SSP_101;
    } else if (src === 'ISC_SSP_102') {
        return ISC_SSP_102;
    } else if (src === 'ISC_SSP_103') {
        return ISC_SSP_103;
    }


    // MODULE 3: Spacecraft tech and arch
    if (src === 'ISC_STA_1') {
        return ISC_STA_1;
    } else if (src === 'ISC_STA_2') {
        return ISC_STA_2;
    } else if (src === 'ISC_STA_3') {
        return ISC_STA_3;
    } else if (src === 'ISC_STA_4') {
        return ISC_STA_4;
    } else if (src === 'ISC_STA_5') {
        return ISC_STA_5;
    } else if (src === 'ISC_STA_6') {
        return ISC_STA_6;
    } else if (src === 'ISC_STA_7') {
        return ISC_STA_7;
    } else if (src === 'ISC_STA_8') {
        return ISC_STA_8;
    } else if (src === 'ISC_STA_9') {
        return ISC_STA_9;
    } else if (src === 'ISC_STA_10') {
        return ISC_STA_10;
    } else if (src === 'ISC_STA_11') {
        return ISC_STA_11;
    } else if (src === 'ISC_STA_12') {
        return ISC_STA_12;
    } else if (src === 'ISC_STA_13') {
        return ISC_STA_13;
    } else if (src === 'ISC_STA_14') {
        return ISC_STA_14;
    } else if (src === 'ISC_STA_15') {
        return ISC_STA_15;
    } else if (src === 'ISC_STA_16') {
        return ISC_STA_16;
    } else if (src === 'ISC_STA_17') {
        return ISC_STA_17;
    } else if (src === 'ISC_STA_18') {
        return ISC_STA_18;
    } else if (src === 'ISC_STA_19') {
        return ISC_STA_19;
    } else if (src === 'ISC_STA_20') {
        return ISC_STA_20;
    } else if (src === 'ISC_STA_21') {
        return ISC_STA_21;
    } else if (src === 'ISC_STA_22') {
        return ISC_STA_22;
    } else if (src === 'ISC_STA_23') {
        return ISC_STA_23;
    } else if (src === 'ISC_STA_24') {
        return ISC_STA_24;
    } else if (src === 'ISC_STA_25') {
        return ISC_STA_25;
    } else if (src === 'ISC_STA_26') {
        return ISC_STA_26;
    } else if (src === 'ISC_STA_27') {
        return ISC_STA_27;
    } else if (src === 'ISC_STA_28') {
        return ISC_STA_28;
    } else if (src === 'ISC_STA_29') {
        return ISC_STA_29;
    } else if (src === 'ISC_STA_30') {
        return ISC_STA_30;
    } else if (src === 'ISC_STA_31') {
        return ISC_STA_31;
    } else if (src === 'ISC_STA_32') {
        return ISC_STA_32;
    } else if (src === 'ISC_STA_33') {
        return ISC_STA_33;
    } else if (src === 'ISC_STA_34') {
        return ISC_STA_34;
    } else if (src === 'ISC_STA_35') {
        return ISC_STA_35;
    } else if (src === 'ISC_STA_36') {
        return ISC_STA_36;
    } else if (src === 'ISC_STA_37') {
        return ISC_STA_37;
    } else if (src === 'ISC_STA_38') {
        return ISC_STA_38;
    } else if (src === 'ISC_STA_39') {
        return ISC_STA_39;
    } else if (src === 'ISC_STA_40') {
        return ISC_STA_40;
    } else if (src === 'ISC_STA_41') {
        return ISC_STA_41;
    } else if (src === 'ISC_STA_42') {
        return ISC_STA_42;
    } else if (src === 'ISC_STA_43') {
        return ISC_STA_43;
    } else if (src === 'ISC_STA_44') {
        return ISC_STA_44;
    } else if (src === 'ISC_STA_45') {
        return ISC_STA_45;
    } else if (src === 'ISC_STA_46') {
        return ISC_STA_46;
    } else if (src === 'ISC_STA_47') {
        return ISC_STA_47;
    } else if (src === 'ISC_STA_48') {
        return ISC_STA_48;
    } else if (src === 'ISC_STA_49') {
        return ISC_STA_49;
    } else if (src === 'ISC_STA_50') {
        return ISC_STA_50;
    } else if (src === 'ISC_STA_51') {
        return ISC_STA_51;
    } else if (src === 'ISC_STA_52') {
        return ISC_STA_52;
    } else if (src === 'ISC_STA_53') {
        return ISC_STA_53;
    } else if (src === 'ISC_STA_54') {
        return ISC_STA_54;
    } else if (src === 'ISC_STA_55') {
        return ISC_STA_55;
    } else if (src === 'ISC_STA_56') {
        return ISC_STA_56;
    } else if (src === 'ISC_STA_57') {
        return ISC_STA_57;
    } else if (src === 'ISC_STA_58') {
        return ISC_STA_58;
    } else if (src === 'ISC_STA_59') {
        return ISC_STA_59;
    } else if (src === 'ISC_STA_60') {
        return ISC_STA_60;
    } else if (src === 'ISC_STA_61') {
        return ISC_STA_61;
    } else if (src === 'ISC_STA_62') {
        return ISC_STA_62;
    } else if (src === 'ISC_STA_63') {
        return ISC_STA_63;
    } else if (src === 'ISC_STA_64') {
        return ISC_STA_64;
    } else if (src === 'ISC_STA_65') {
        return ISC_STA_65;
    } else if (src === 'ISC_STA_66') {
        return ISC_STA_66;
    } else if (src === 'ISC_STA_67') {
        return ISC_STA_67;
    } else if (src === 'ISC_STA_68') {
        return ISC_STA_68;
    } else if (src === 'ISC_STA_69') {
        return ISC_STA_69;
    } else if (src === 'ISC_STA_70') {
        return ISC_STA_70;
    } else if (src === 'ISC_STA_71') {
        return ISC_STA_71;
    } else if (src === 'ISC_STA_72') {
        return ISC_STA_72;
    } else if (src === 'ISC_STA_73') {
        return ISC_STA_73;
    } else if (src === 'ISC_STA_74') {
        return ISC_STA_74;
    } else if (src === 'ISC_STA_75') {
        return ISC_STA_75;
    } else if (src === 'ISC_STA_76') {
        return ISC_STA_76;
    } else if (src === 'ISC_STA_77') {
        return ISC_STA_77;
    } else if (src === 'ISC_STA_78') {
        return ISC_STA_78;
    } else if (src === 'ISC_STA_79') {
        return ISC_STA_79;
    } else if (src === 'ISC_STA_80') {
        return ISC_STA_80;
    } else if (src === 'ISC_STA_81') {
        return ISC_STA_81;
    } else if (src === 'ISC_STA_82') {
        return ISC_STA_82;
    } else if (src === 'ISC_STA_83') {
        return ISC_STA_83;
    } else if (src === 'ISC_STA_84') {
        return ISC_STA_84;
    } else if (src === 'ISC_STA_85') {
        return ISC_STA_85;
    } else if (src === 'ISC_STA_86') {
        return ISC_STA_86;
    } else if (src === 'ISC_STA_87') {
        return ISC_STA_87;
    } else if (src === 'ISC_STA_88') {
        return ISC_STA_88;
    } else if (src === 'ISC_STA_89') {
        return ISC_STA_89;
    } else if (src === 'ISC_STA_90') {
        return ISC_STA_90;
    } else if (src === 'ISC_STA_91') {
        return ISC_STA_91;
    } else if (src === 'ISC_STA_92') {
        return ISC_STA_92;
    } else if (src === 'ISC_STA_93') {
        return ISC_STA_93;
    } else if (src === 'ISC_STA_94') {
        return ISC_STA_94;
    } else if (src === 'ISC_STA_95') {
        return ISC_STA_95;
    } else if (src === 'ISC_STA_96') {
        return ISC_STA_96;
    } else if (src === 'ISC_STA_97') {
        return ISC_STA_97;
    } else if (src === 'ISC_STA_98') {
        return ISC_STA_98;
    } else if (src === 'ISC_STA_99') {
        return ISC_STA_99;
    } else if (src === 'ISC_STA_100') {
        return ISC_STA_100;
    } else if (src === 'ISC_STA_101') {
        return ISC_STA_101;
    } else if (src === 'ISC_STA_102') {
        return ISC_STA_102;
    } else if (src === 'ISC_STA_103') {
        return ISC_STA_103;
    } else if (src === 'ISC_STA_104') {
        return ISC_STA_104;
    } else if (src === 'ISC_STA_105') {
        return ISC_STA_105;
    } else if (src === 'ISC_STA_106') {
        return ISC_STA_106;
    } else if (src === 'ISC_STA_107') {
        return ISC_STA_107;
    } else if (src === 'ISC_STA_108') {
        return ISC_STA_108;
    } else if (src === 'ISC_STA_109') {
        return ISC_STA_109;
    } else if (src === 'ISC_STA_110') {
        return ISC_STA_110;
    } else if (src === 'ISC_STA_111') {
        return ISC_STA_111;
    } else if (src === 'ISC_STA_112') {
        return ISC_STA_112;
    } else if (src === 'ISC_STA_113') {
        return ISC_STA_113;
    } else if (src === 'ISC_STA_114') {
        return ISC_STA_114;
    } else if (src === 'ISC_STA_115') {
        return ISC_STA_115;
    } else if (src === 'ISC_STA_116') {
        return ISC_STA_116;
    } else if (src === 'ISC_STA_117') {
        return ISC_STA_117;
    } else if (src === 'ISC_STA_118') {
        return ISC_STA_118;
    } else if (src === 'ISC_STA_119') {
        return ISC_STA_119;
    } else if (src === 'ISC_STA_120') {
        return ISC_STA_120;
    } else if (src === 'ISC_STA_121') {
        return ISC_STA_121;
    } else if (src === 'ISC_STA_122') {
        return ISC_STA_122;
    } else if (src === 'ISC_STA_123') {
        return ISC_STA_123;
    } else if (src === 'ISC_STA_124') {
        return ISC_STA_124;
    } else if (src === 'ISC_STA_125') {
        return ISC_STA_125;
    } else if (src === 'ISC_STA_126') {
        return ISC_STA_126;
    } else if (src === 'ISC_STA_127') {
        return ISC_STA_127;
    } else if (src === 'ISC_STA_128') {
        return ISC_STA_128;
    } else if (src === 'ISC_STA_129') {
        return ISC_STA_129;
    }







    // MODULE 2: Space env and orbits
    if (src === 'ISC_SEO_1') {
        return ISC_SEO_1;
    } else if (src === 'ISC_SEO_2') {
        return ISC_SEO_2;
    } else if (src === 'ISC_SEO_3') {
        return ISC_SEO_3;
    } else if (src === 'ISC_SEO_4') {
        return ISC_SEO_4;
    } else if (src === 'ISC_SEO_5') {
        return ISC_SEO_5;
    } else if (src === 'ISC_SEO_6') {
        return ISC_SEO_6;
    } else if (src === 'ISC_SEO_7') {
        return ISC_SEO_7;
    } else if (src === 'ISC_SEO_8') {
        return ISC_SEO_8;
    } else if (src === 'ISC_SEO_9') {
        return ISC_SEO_9;
    } else if (src === 'ISC_SEO_10') {
        return ISC_SEO_10;
    } else if (src === 'ISC_SEO_11') {
        return ISC_SEO_11;
    } else if (src === 'ISC_SEO_12') {
        return ISC_SEO_12;
    } else if (src === 'ISC_SEO_13') {
        return ISC_SEO_13;
    } else if (src === 'ISC_SEO_14') {
        return ISC_SEO_14;
    } else if (src === 'ISC_SEO_15') {
        return ISC_SEO_15;
    } else if (src === 'ISC_SEO_16') {
        return ISC_SEO_16;
    } else if (src === 'ISC_SEO_17') {
        return ISC_SEO_17;
    } else if (src === 'ISC_SEO_18') {
        return ISC_SEO_18;
    } else if (src === 'ISC_SEO_19') {
        return ISC_SEO_19;
    } else if (src === 'ISC_SEO_20') {
        return ISC_SEO_20;
    } else if (src === 'ISC_SEO_21') {
        return ISC_SEO_21;
    } else if (src === 'ISC_SEO_22') {
        return ISC_SEO_22;
    } else if (src === 'ISC_SEO_23') {
        return ISC_SEO_23;
    } else if (src === 'ISC_SEO_24') {
        return ISC_SEO_24;
    } else if (src === 'ISC_SEO_25') {
        return ISC_SEO_25;
    } else if (src === 'ISC_SEO_26') {
        return ISC_SEO_26;
    } else if (src === 'ISC_SEO_27') {
        return ISC_SEO_27;
    } else if (src === 'ISC_SEO_28') {
        return ISC_SEO_28;
    } else if (src === 'ISC_SEO_29') {
        return ISC_SEO_29;
    } else if (src === 'ISC_SEO_30') {
        return ISC_SEO_30;
    } else if (src === 'ISC_SEO_31') {
        return ISC_SEO_31;
    } else if (src === 'ISC_SEO_32') {
        return ISC_SEO_32;
    } else if (src === 'ISC_SEO_33') {
        return ISC_SEO_33;
    } else if (src === 'ISC_SEO_34') {
        return ISC_SEO_34;
    } else if (src === 'ISC_SEO_35') {
        return ISC_SEO_35;
    } else if (src === 'ISC_SEO_36') {
        return ISC_SEO_36;
    } else if (src === 'ISC_SEO_37') {
        return ISC_SEO_37;
    } else if (src === 'ISC_SEO_38') {
        return ISC_SEO_38;
    } else if (src === 'ISC_SEO_39') {
        return ISC_SEO_39;
    } else if (src === 'ISC_SEO_40') {
        return ISC_SEO_40;
    } else if (src === 'ISC_SEO_41') {
        return ISC_SEO_41;
    } else if (src === 'ISC_SEO_42') {
        return ISC_SEO_42;
    } else if (src === 'ISC_SEO_43') {
        return ISC_SEO_43;
    } else if (src === 'ISC_SEO_44') {
        return ISC_SEO_44;
    } else if (src === 'ISC_SEO_45') {
        return ISC_SEO_45;
    } else if (src === 'ISC_SEO_46') {
        return ISC_SEO_46;
    } else if (src === 'ISC_SEO_47') {
        return ISC_SEO_47;
    } else if (src === 'ISC_SEO_48') {
        return ISC_SEO_48;
    } else if (src === 'ISC_SEO_49') {
        return ISC_SEO_49;
    } else if (src === 'ISC_SEO_50') {
        return ISC_SEO_50;
    }


    // MODULE 1: Space mission overview
    if (src === 'ISC_SMO_1') {
        return ISC_SMO_1;
    } else if (src === 'ISC_SMO_2') {
        return ISC_SMO_2;
    } else if (src === 'ISC_SMO_3') {
        return ISC_SMO_3;
    } else if (src === 'ISC_SMO_4') {
        return ISC_SMO_4;
    } else if (src === 'ISC_SMO_5') {
        return ISC_SMO_5;
    } else if (src === 'ISC_SMO_6') {
        return ISC_SMO_6;
    } else if (src === 'ISC_SMO_7') {
        return ISC_SMO_7;
    } else if (src === 'ISC_SMO_8') {
        return ISC_SMO_8;
    } else if (src === 'ISC_SMO_9') {
        return ISC_SMO_9;
    } else if (src === 'ISC_SMO_10') {
        return ISC_SMO_10;
    } else if (src === 'ISC_SMO_11') {
        return ISC_SMO_11;
    } else if (src === 'ISC_SMO_12') {
        return ISC_SMO_12;
    } else if (src === 'ISC_SMO_13') {
        return ISC_SMO_13;
    } else if (src === 'ISC_SMO_14') {
        return ISC_SMO_14;
    } else if (src === 'ISC_SMO_15') {
        return ISC_SMO_15;
    } else if (src === 'ISC_SMO_16') {
        return ISC_SMO_16;
    } else if (src === 'ISC_SMO_17') {
        return ISC_SMO_17;
    } else if (src === 'ISC_SMO_18') {
        return ISC_SMO_18;
    } else if (src === 'ISC_SMO_19') {
        return ISC_SMO_19;
    } else if (src === 'ISC_SMO_20') {
        return ISC_SMO_20;
    } else if (src === 'ISC_SMO_21') {
        return ISC_SMO_21;
    } else if (src === 'ISC_SMO_22') {
        return ISC_SMO_22;
    } else if (src === 'ISC_SMO_23') {
        return ISC_SMO_23;
    } else if (src === 'ISC_SMO_24') {
        return ISC_SMO_24;
    } else if (src === 'ISC_SMO_25') {
        return ISC_SMO_25;
    } else if (src === 'ISC_SMO_26') {
        return ISC_SMO_26;
    } else if (src === 'ISC_SMO_27') {
        return ISC_SMO_27;
    } else if (src === 'ISC_SMO_28') {
        return ISC_SMO_28;
    } else if (src === 'ISC_SMO_29') {
        return ISC_SMO_29;
    } else if (src === 'ISC_SMO_30') {
        return ISC_SMO_30;
    }























    // OLD SLIDES
    if(src === 'LifecycleCost'){
        return LifecycleCost;
    }
    else if(src === 'NASAModel'){
        return NASAModel;
    }
    else if(src === 'Approaches'){
        return Approaches;
    }
    else if(src === 'AERO401Slide1'){
        return AERO401Slide1;
    }
    else if(src === 'AERO401Slide2'){
        return AERO401Slide2;
    }
    else if(src === 'AERO401Slide3'){
        return AERO401Slide3;
    }
    else if(src === 'AERO401Slide4'){
        return AERO401Slide4;
    }
    else if(src === 'AERO401Slide5'){
        return AERO401Slide5;
    }
    else if(src === 'AERO401Slide6'){
        return AERO401Slide6;
    }
    else if(src === 'AERO401Slide7'){
        return AERO401Slide7;
    }
    else if(src === 'AERO401Slide8'){
        return AERO401Slide8;
    }
    else if(src === 'AERO401Slide9'){
        return AERO401Slide9;
    }
    else if(src === 'AERO401Slide10'){
        return AERO401Slide10;
    }
    else if(src === 'AERO401Slide11'){
        return AERO401Slide11;
    }
    else if(src === 'AERO401Slide12'){
        return AERO401Slide12;
    }
    else if(src === 'AERO401Slide13'){
        return AERO401Slide13;
    }
    else if(src === 'AERO401Slide14'){
        return AERO401Slide14;
    }
    else if(src === 'AERO401Slide15'){
        return AERO401Slide15;
    }
    else if(src === 'AERO401Slide16'){
        return AERO401Slide16;
    }
    else if(src === 'AERO401Slide17'){
        return AERO401Slide17;
    }
    else if(src === 'AERO401Slide18'){
        return AERO401Slide18;
    }
    else if(src === 'AERO401Slide19'){
        return AERO401Slide19;
    }
    else if(src === 'AERO401Slide20'){
        return AERO401Slide20;
    }
    else if(src === 'AERO401Slide21'){
        return AERO401Slide21;
    }
    else if(src === 'AERO401Slide22'){
        return AERO401Slide22;
    }
    else if(src === 'AERO401Slide23'){
        return AERO401Slide23;
    }
    else if(src === 'AERO401Slide24'){
        return AERO401Slide24;
    }
    else if(src === 'AERO401Slide25'){
        return AERO401Slide25;
    }
    else if(src === 'AERO401Slide26'){
        return AERO401Slide26;
    }
    else if(src === 'AERO401Slide27'){
        return AERO401Slide27;
    }
    else if(src === 'AERO401Slide28'){
        return AERO401Slide28;
    }
    else if(src === 'AERO401Slide29'){
        return AERO401Slide29;
    }
    else if(src === 'AERO401Slide30'){
        return AERO401Slide30;
    }
    else if(src === 'AERO401Slide31'){
        return AERO401Slide31;
    }
    else if(src === 'AERO401Slide32'){
        return AERO401Slide32;
    }
    else if(src === 'AERO401Slide33'){
        return AERO401Slide33;
    }
    else if(src === 'AERO401Slide34'){
        return AERO401Slide34;
    }
    else if(src === 'AERO401Slide35'){
        return AERO401Slide35;
    }
    else if(src === 'AERO401Slide36'){
        return AERO401Slide36;
    }
    else if(src === 'AERO401Slide37'){
        return AERO401Slide37;
    }

    else if(src === 'COSTD_1'){
        return COSTD_1;
    }
    else if(src === 'COSTD_2'){
        return COSTD_2;
    }
    else if(src === 'COSTD_3'){
        return COSTD_3;
    }
    else if(src === 'COSTD_4'){
        return COSTD_4;
    }
    else if(src === 'COSTD_5'){
        return COSTD_5;
    }
    else if(src === 'COSTD_6'){
        return COSTD_6;
    }
    else if(src === 'COSTD_7'){
        return COSTD_7;
    }
    else if(src === 'COSTD_8'){
        return COSTD_8;
    }
    else if(src === 'COSTD_9'){
        return COSTD_9;
    }
    else if(src === 'COSTD_10'){
        return COSTD_10;
    }
    else if(src === 'COSTD_11'){
        return COSTD_11;
    }
    else if(src === 'COSTD_12'){
        return COSTD_12;
    }
    else if(src === 'COSTD_13'){
        return COSTD_13;
    }
    else if(src === 'COSTD_14'){
        return COSTD_14;
    }
    else if(src === 'COSTD_15'){
        return COSTD_15;
    }
    else if(src === 'COSTD_16'){
        return COSTD_16;
    }
    else if(src === 'COSTD_17'){
        return COSTD_17;
    }
    else if(src === 'COSTD_18'){
        return COSTD_18;
    }
    else if(src === 'COSTD_19'){
        return COSTD_19;
    }
    else if(src === 'COSTD_20'){
        return COSTD_20;
    }
    else if(src === 'COSTD_21'){
        return COSTD_21;
    }
    else if(src === 'COSTD_22'){
        return COSTD_22;
    }
    else if(src === 'COSTD_23'){
        return COSTD_23;
    }
    else if(src === 'COSTD_24'){
        return COSTD_24;
    }
    else if(src === 'COSTD_25'){
        return COSTD_25;
    }
    else if(src === 'COSTD_26'){
        return COSTD_26;
    }
    else if(src === 'COSTD_27'){
        return COSTD_27;
    }


    else if(src === 'DODS_0'){
        return DODS_0;
    }
    else if(src === 'DODS_1'){
        return DODS_1;
    }
    else if(src === 'DODS_2'){
        return DODS_2;
    }
    else if(src === 'DODS_3'){
        return DODS_3;
    }
    else if(src === 'DODS_4'){
        return DODS_4;
    }
    else if(src === 'DODS_5'){
        return DODS_5;
    }
    else if(src === 'DODS_6'){
        return DODS_6;
    }
    else if(src === 'DODS_7'){
        return DODS_7;
    }
    else if(src === 'DODS_8'){
        return DODS_8;
    }
    else if(src === 'DODS_9'){
        return DODS_9;
    }
    else if(src === 'DODS_10'){
        return DODS_10;
    }
    else if(src === 'DODS_11'){
        return DODS_11;
    }
    else if(src === 'DODS_12'){
        return DODS_12;
    }
    else if(src === 'DODS_13'){
        return DODS_13;
    }
    else if(src === 'DODS_14'){
        return DODS_14;
    }
    else if(src === 'DODS_15'){
        return DODS_15;
    }
    else if(src === 'DODS_16'){
        return DODS_16;
    }
    else if(src === 'DODS_17'){
        return DODS_17;
    }
    else if(src === 'DODS_18'){
        return DODS_18;
    }
    else if(src === 'DODS_19'){
        return DODS_19;
    }
    else if(src === 'DODS_20'){
        return DODS_20;
    }
    else if(src === 'DODS_21'){
        return DODS_21;
    }
    else if(src === 'DODS_22'){
        return DODS_22;
    }
    else if(src === 'DODS_23'){
        return DODS_23;
    }
    else if(src === 'DODS_24'){
        return DODS_24;
    }
    else if(src === 'DODS_25'){
        return DODS_25;
    }
    else if(src === 'DODS_26'){
        return DODS_26;
    }
    else if(src === 'DODS_27'){
        return DODS_27;
    }
    else if(src === 'DODS_28'){
        return DODS_28;
    }
    else if(src === 'DODS_29'){
        return DODS_29;
    }
    else if(src === 'DODS_30'){
        return DODS_30;
    }
    else if(src === 'DODS_31'){
        return DODS_31;
    }
    else if(src === 'DODS_32'){
        return DODS_32;
    }
    else if(src === 'DODS_33'){
        return DODS_33;
    }
    else if(src === 'DODS_34'){
            return DODS_34;
        }
    else if(src === 'DODS_35'){
            return DODS_35;
        }
    else if(src === 'DODS_36'){
            return DODS_36;
        }
    else if(src === 'DODS_37'){
            return DODS_37;
        }
    else if(src === 'DODS_38'){
            return DODS_38;
        }
    else if(src === 'DODS_39'){
            return DODS_39;
        }
    else if(src === 'DODS_40'){
            return DODS_40;
        }
    else if(src === 'DODS_41'){
            return DODS_41;
        }
    else if(src === 'DODS_42'){
            return DODS_42;
        }
    else if(src === 'DODS_43'){
            return DODS_43;
        }
    else if(src === 'DODS_44'){
            return DODS_44;
        }
    else if(src === 'DODS_45'){
            return DODS_45;
        }
    else if(src === 'DODS_46'){
            return DODS_46;
        }
    else if(src === 'DODS_47'){
            return DODS_47;
        }
    else if(src === 'DODS_48'){
            return DODS_48;
        }
    else if(src === 'DODS_49'){
            return DODS_49;
        }
    else if(src === 'DODS_50'){
            return DODS_50;
        }
    else if(src === 'DODS_51'){
            return DODS_51;
        }
    else if(src === 'DODS_52'){
            return DODS_52;
        }
    else if(src === 'DODS_53'){
            return DODS_53;
        }
    else if(src === 'DODS_54'){
            return DODS_54;
        }
    else if(src === 'DODS_55'){
            return DODS_55;
        }
    else if(src === 'DODS_56'){
            return DODS_56;
        }
    else if(src === 'DODS_57'){
            return DODS_57;
        }



    else if(src === 'Slide1LCCD'){
            return Slide1LCCD;
        }
    else if(src === 'Slide2LCCD'){
            return Slide2LCCD;
        }

    else if(src === 'LC_1'){
            return LC_1;
        }
    else if(src === 'LC_2'){
            return LC_2;
        }
    else if(src === 'LC_3'){
            return LC_3;
        }
    else if(src === 'LC_4'){
            return LC_4;
        }
    else if(src === 'LC_5'){
            return LC_5;
        }
    else if(src === 'LC_6'){
            return LC_6;
        }
    else if(src === 'LC_7'){
            return LC_7;
        }
    else if(src === 'LC_8'){
            return LC_8;
        }
    else if(src === 'ITSC_1'){
            return ITSC_1;
        }
    else if(src === 'ITSC_2'){
            return ITSC_2;
        }
    else if(src === 'ITSC_3'){
            return ITSC_3;
        }
    else if(src === 'ITSC_4'){
            return ITSC_4;
        }
    else if(src === 'ITSC_5'){
            return ITSC_5;
        }
    else if(src === 'ITSC_6'){
            return ITSC_6;
        }
    else if(src === 'ITSC_7'){
            return ITSC_7;
        }
    else if(src === 'ITSC_8'){
            return ITSC_8;
        }
    else if(src === 'ITSC_9'){
            return ITSC_9;
        }
    else if(src === 'ITSC_10'){
            return ITSC_10;
        }
    else if(src === 'ITSC_11'){
            return ITSC_11;
        }
    else if(src === 'ITSC_12'){
            return ITSC_12;
        }
    else if(src === 'ITSC_13'){
            return ITSC_13;
        }
    else if(src === 'ITSC_14'){
            return ITSC_14;
        }
    else if(src === 'ITSC_15'){
            return ITSC_15;
        }
    else if(src === 'ITSC_16'){
            return ITSC_16;
        }
    else if(src === 'ITSC_17'){
            return ITSC_17;
        }
    else if(src === 'ITSC_18'){
            return ITSC_18;
        }
    else if(src === 'ITSC_19'){
            return ITSC_19;
        }
    else if(src === 'ITSC_20'){
            return ITSC_20;
        }
    else if(src === 'ITSC_21'){
            return ITSC_21;
        }
    else if(src === 'ITSC_22'){
            return ITSC_22;
        }
    else if(src === 'ITSC_23'){
            return ITSC_23;
        }
    else if(src === 'ITSC_24'){
            return ITSC_24;
        }
    else if(src === 'ITSC_25'){
            return ITSC_25;
        }
    else if(src === 'ITSC_26') {
            return ITSC_26;
        }
    else if(src === 'ITSC_27'){
            return ITSC_27;
        }
    else if(src === 'ITSC_28'){
            return ITSC_28;
        }
    else if(src === 'ITSC_29'){
            return ITSC_29;
        }
    // else if(src === 'ITSC_30'){
    //         return ITSC_30;
    //     }
    // else if(src === 'ITSC_31'){
    //         return ITSC_31;
    //     }
    // else if(src === 'ITSC_32'){
    //         return ITSC_32;
    //     }
    // else if(src === 'ITSC_33'){
    //         return ITSC_33;
    //     }
    // else if(src === 'ITSC_34'){
    //         return ITSC_34;
    //     }
    // else if(src === 'ITSC_35'){
    //         return ITSC_35;
    //     }
    // else if(src === 'ITSC_36'){
    //         return ITSC_36;
    //     }
    // else if(src === 'ITSC_37'){
    //         return ITSC_37;
    //     }
    // else if(src === 'ITSC_38'){
    //         return ITSC_38;
    //     }
    // else if(src === 'ITSC_39'){
    //         return ITSC_39;
    //     }
    // else if(src === 'ITSC_40'){
    //         return ITSC_40;
    //     }

    else if(src === 'SEAO_1'){
            return SEAO_1;
        }
    else if(src === 'SEAO_2'){
            return SEAO_2;
        }
    else if(src === 'SEAO_3'){
            return SEAO_3;
        }
    else if(src === 'SEAO_4'){
            return SEAO_4;
        }
    else if(src === 'SEAO_5'){
            return SEAO_5;
        }
    else if(src === 'SEAO_6'){
            return SEAO_6;
        }
    else if(src === 'SEAO_7'){
            return SEAO_7;
        }
    else if(src === 'SEAO_8'){
            return SEAO_8;
        }
    else if(src === 'SEAO_9'){
            return SEAO_9;
        }
    else if(src === 'SEAO_10'){
            return SEAO_10;
        }
    else if(src === 'SEAO_11'){
            return SEAO_11;
        }
    else if(src === 'SEAO_12'){
            return SEAO_12;
        }
    else if(src === 'SEAO_13'){
            return SEAO_13;
        }
    else if(src === 'SEAO_14'){
            return SEAO_14;
        }
    else if(src === 'SEAO_15'){
            return SEAO_15;
        }
    else if(src === 'SEAO_16'){
            return SEAO_16;
        }
    else if(src === 'SEAO_17'){
            return SEAO_17;
        }
    else if(src === 'SEAO_18'){
            return SEAO_18;
        }
    else if(src === 'SEAO_19'){
            return SEAO_19;
        }
    else if(src === 'SEAO_20'){
            return SEAO_20;
        }
    else if(src === 'SEAO_21'){
            return SEAO_21;
        }
    else if(src === 'SEAO_22'){
            return SEAO_22;
        }
    else if(src === 'SEAO_23'){
            return SEAO_23;
        }
    else if(src === 'SEAO_24'){
            return SEAO_24;
        }
    else if(src === 'SEAO_25'){
            return SEAO_25;
        }
    else if(src === 'SEAO_26') {
            return SEAO_26;
        }
    else if(src === 'SEAO_27'){
            return SEAO_27;
        }
    else if(src === 'SEAO_28'){
            return SEAO_28;
        }
    else if(src === 'SEAO_29'){
            return SEAO_29;
        }
    else if(src === 'SEAO_30'){
            return SEAO_30;
        }
    else if(src === 'SEAO_31'){
            return SEAO_31;
        }
    else if(src === 'SEAO_32'){
            return SEAO_32;
        }
    // else if(src === 'SEAO_33'){
    //         return SEAO_33;
    //     }
    // else if(src === 'SEAO_34'){
    //         return SEAO_34;
    //     }
    // else if(src === 'SEAO_35'){
    //         return SEAO_35;
    //     }
    // else if(src === 'SEAO_36'){
    //         return SEAO_36;
    //     }
    // else if(src === 'SEAO_37'){
    //         return SEAO_37;
    //     }
    // else if(src === 'SEAO_38'){
    //         return SEAO_38;
    //     }
    // else if(src === 'SEAO_39'){
    //         return SEAO_39;
    //     }
    // else if(src === 'SEAO_40'){
    //         return SEAO_40;
    //     }
    // else if(src === 'SEAO_41'){
    //         return SEAO_41;
    //     }
    // else if(src === 'SEAO_42'){
    //         return SEAO_42;
    //     }
    // else if(src === 'SEAO_43'){
    //         return SEAO_43;
    //     }
    // else if(src === 'SEAO_44'){
    //         return SEAO_44;
    //     }
    // else if(src === 'SEAO_45'){
    //         return SEAO_45;
    //     }
    // else if(src === 'SEAO_46'){
    //         return SEAO_46;
    //     }
    // else if(src === 'SEAO_47'){
    //         return SEAO_47;
    //     }
    // else if(src === 'SEAO_48'){
    //         return SEAO_48;
    //     }
    // else if(src === 'SEAO_49'){
    //         return SEAO_49;
    //     }
}
