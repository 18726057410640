var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-list-item",
        { staticClass: "white--text" },
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "text-h6" },
                [
                  _c("span", [_vm._v("Virtual Assistant")]),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      class: {
                                        "yellow--text": _vm.recommender_status,
                                      },
                                      staticStyle: { float: "right" },
                                      attrs: {
                                        dark: "",
                                        icon: "",
                                        height: "24",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-cog")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: { multiple: "", "active-class": "" },
                              model: {
                                value: _vm.settings,
                                callback: function ($$v) {
                                  _vm.settings = $$v
                                },
                                expression: "settings",
                              },
                            },
                            [
                              _c("v-list-item", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ active }) {
                                      return [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c("v-checkbox", {
                                              attrs: { "input-value": active },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Recommender"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-list-item-subtitle", { staticClass: "white--text" }, [
                _vm._v(
                  "\n                    Dialogue History\n                "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", {
        staticClass: "white",
        staticStyle: { "margin-top": "5px" },
      }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticStyle: { "padding-bottom": "75px" }, attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-slide-y-reverse-transition",
                { attrs: { group: "" } },
                _vm._l(_vm.messages, function (item, idx) {
                  return _c(
                    "v-col",
                    {
                      key: idx,
                      staticStyle: {
                        "padding-top": "6px",
                        "padding-bottom": "6px",
                      },
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-card",
                        { style: _vm.get_message_style(item.sender) },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "text-subtitle-2",
                              staticStyle: {
                                "padding-top": "10px",
                                "padding-bottom": "4px",
                              },
                            },
                            [
                              item.sender === "User"
                                ? _c("div", [_vm._v(_vm._s(_vm.username))])
                                : _vm._e(),
                              _vm._v(" "),
                              item.sender === "Daphne"
                                ? _c("div", [_vm._v(_vm._s(item.sender))])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    right: "",
                                    absolute: "",
                                    "x-small": "",
                                    elevation: "0",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clear_message(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "analogous2" } },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            { staticStyle: { "padding-bottom": "10px" } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(item.text) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          item.more_info !== null
                            ? _c("v-divider", {
                                staticStyle: {
                                  "margin-top": "0",
                                  "margin-bottom": "0",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          item.more_info !== null
                            ? _c(
                                "v-card-actions",
                                {
                                  staticStyle: {
                                    "padding-top": "8px",
                                    "padding-left": "16px",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-subtitle-2" },
                                    [_vm._v("Recommended Material")]
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          item.show = !item.show
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            item.show
                                              ? "mdi-chevron-up"
                                              : "mdi-chevron-down"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.more_info !== null
                            ? _c("v-expand-transition", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.show,
                                        expression: "item.show",
                                      },
                                    ],
                                  },
                                  [
                                    _c("v-divider", {
                                      staticStyle: { margin: "0" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      {
                                        staticStyle: {
                                          "padding-top": "0",
                                          "padding-left": "0",
                                          "padding-right": "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { dense: "" } },
                                          _vm._l(
                                            JSON.parse(
                                              JSON.stringify(item.more_info)
                                            ),
                                            function (module, idx) {
                                              return _c(
                                                "v-list-group",
                                                {
                                                  key: idx,
                                                  attrs: { value: false },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                            " +
                                                                        _vm._s(
                                                                          module.name
                                                                        ) +
                                                                        "\n                                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        (
                                                                          module.confidence *
                                                                          100
                                                                        ).toFixed(
                                                                          1
                                                                        )
                                                                      ) + "%"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    module.slides.slice(0, 5),
                                                    function (slide, idx_s) {
                                                      return _c(
                                                        "v-list-item",
                                                        { key: idx_s },
                                                        [
                                                          _c(
                                                            "v-container",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "10",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                                    Slide " +
                                                                              _vm._s(
                                                                                slide.id
                                                                              ) +
                                                                              "\n                                                                    "
                                                                          ),
                                                                          _c(
                                                                            "v-progress-linear",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    slide.confidence *
                                                                                    100,
                                                                                  rounded:
                                                                                    "",
                                                                                  color:
                                                                                    "analogous2",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "2",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              bottom:
                                                                                "",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activator",
                                                                                  fn: function ({
                                                                                    on,
                                                                                    attrs,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  icon: "",
                                                                                                  small:
                                                                                                    "",
                                                                                                  color:
                                                                                                    "success",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.go_to_slide(
                                                                                                      module,
                                                                                                      slide
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            "v-btn",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-arrow-right-bold"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Go to slide"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        { attrs: { fixed: "", color: "analogous2", height: "90" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center white--text",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          "append-icon": "mdi-send",
                          "background-color": "white",
                        },
                        on: {
                          "click:append": function ($event) {
                            return _vm.send_generative_message()
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.send_generative_message()
                          },
                        },
                        model: {
                          value: _vm.user_message,
                          callback: function ($$v) {
                            _vm.user_message = $$v
                          },
                          expression: "user_message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }