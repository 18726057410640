var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { padding: "0px 12px 0px 12px" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "success", elevation: "24" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.correct_noti = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("\n                    Close\n                ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.correct_noti,
            callback: function ($$v) {
              _vm.correct_noti = $$v
            },
            expression: "correct_noti",
          },
        },
        [_vm._v("\n            Nice job!\n            ")]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error", elevation: "24" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.wrong_noti = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("\n                    Close\n                ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.wrong_noti,
            callback: function ($$v) {
              _vm.wrong_noti = $$v
            },
            expression: "wrong_noti",
          },
        },
        [_vm._v("\n            Incorrect\n            ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticStyle: { "padding-top": "12px" },
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { elevation: "0", "min-height": "700" } },
                [
                  _c("v-card-title", [_vm._v(_vm._s(_vm.module_name))]),
                  _vm._v(" "),
                  _c(
                    "v-card-subtitle",
                    _vm._l(_vm.module.topics, function (topic, idx) {
                      return _c("span", { key: idx }, [
                        _vm._v("\n                        " + _vm._s(topic)),
                        idx < _vm.module.topics.length - 1
                          ? _c("span", [_vm._v(", ")])
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticStyle: { "margin-top": "0" } }),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-carousel",
                        {
                          ref: "myCarousel",
                          attrs: {
                            "hide-delimiter-background": "",
                            "hide-delimiters": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prev",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            disabled: _vm.disable_back,
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-left-bold"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "next",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            disabled: _vm.disable_next,
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-right-bold"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.slide_idx,
                            callback: function ($$v) {
                              _vm.slide_idx = $$v
                            },
                            expression: "slide_idx",
                          },
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _vm._l(_vm.slides, function (slide, idx) {
                            return _c(
                              "v-carousel-item",
                              { key: idx },
                              [
                                slide.type === "info"
                                  ? _c("v-img", {
                                      staticStyle: { cursor: "zoom-in" },
                                      attrs: {
                                        "max-height": "500",
                                        src: _vm.get_slide_image(slide.src),
                                        contain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggle_overlay(true)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-overlay",
                                  {
                                    model: {
                                      value: _vm.show_overlay,
                                      callback: function ($$v) {
                                        _vm.show_overlay = $$v
                                      },
                                      expression: "show_overlay",
                                    },
                                  },
                                  [
                                    slide.type === "info"
                                      ? _c("v-img", {
                                          staticStyle: { cursor: "zoom-out" },
                                          attrs: {
                                            src: _vm.get_slide_image(slide.src),
                                            "max-width": "960",
                                            "max-height": "720",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggle_overlay(false)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                slide.type === "quiz_start"
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticStyle: {
                                              "padding-left": "70px",
                                              "padding-right": "70px",
                                              "padding-top": "20px",
                                            },
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              { staticClass: "justify-center" },
                                              [
                                                _vm._v(
                                                  "You have completed the first section of this task"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "text-center text-body-1",
                                              },
                                              [
                                                _vm._v(
                                                  "The second section contains a short quiz to gauge your understanding of the presented material. You will have one attempt per question, and are allowed to return to the material if necessary (open book). Continue to the next slide when you are ready to begin."
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                slide.type === "quiz_end"
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticStyle: {
                                              "padding-left": "70px",
                                              "padding-right": "70px",
                                              "padding-top": "20px",
                                            },
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              { staticClass: "justify-center" },
                                              [_vm._v("Assessment Complete")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "text-center text-body-1",
                                              },
                                              [
                                                _vm._v(
                                                  "This is the end of the module quiz. Please proceed to the module exam when ready."
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { color: "primary" },
                                                on: { click: _vm.begin_exam },
                                              },
                                              [_vm._v("Begin Exam")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                slide.type === "exam_finish"
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticStyle: {
                                              "padding-left": "70px",
                                              "padding-right": "70px",
                                              "padding-top": "20px",
                                            },
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              { staticClass: "justify-center" },
                                              [_vm._v("Task Complete")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "text-center text-body-1",
                                              },
                                              [
                                                _vm._v(
                                                  "This slide denotes the end of the module exam. You have now unlocked adaptive testing on the content of this module."
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-card-actions",
                                              { staticClass: "justify-center" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "align-center",
                                                    attrs: { color: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.go_to_next_condition()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v("Continue"),
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { right: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-arrow-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                slide.type === "question"
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticStyle: {
                                              "padding-left": "70px",
                                              "padding-right": "70px",
                                              "padding-top": "20px",
                                            },
                                            attrs: { elevation: "0" },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticStyle: {
                                                  "padding-bottom": "0",
                                                  "word-break": "break-word",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(slide.question.text)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-container",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  [
                                                    slide.answered === false
                                                      ? [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              model: {
                                                                value:
                                                                  slide.choice_id,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      slide,
                                                                      "choice_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "slide.choice_id",
                                                              },
                                                            },
                                                            _vm._l(
                                                              slide.question
                                                                .choices,
                                                              function (
                                                                option,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "v-radio",
                                                                  {
                                                                    key: idx,
                                                                    attrs: {
                                                                      label:
                                                                        option.text,
                                                                      value:
                                                                        idx,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    slide.answered === true
                                                      ? [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              model: {
                                                                value:
                                                                  slide.choice_id,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      slide,
                                                                      "choice_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "slide.choice_id",
                                                              },
                                                            },
                                                            _vm._l(
                                                              slide.question
                                                                .choices,
                                                              function (
                                                                option,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "v-radio",
                                                                  {
                                                                    key: idx,
                                                                    attrs: {
                                                                      label:
                                                                        option.text,
                                                                      value:
                                                                        idx,
                                                                      color:
                                                                        _vm.choice_color(
                                                                          option.correct
                                                                        ),
                                                                      disabled:
                                                                        !option.correct,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-row",
                                                  {
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  [
                                                    slide.answered === false
                                                      ? [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  slide.choice_id ===
                                                                  -1,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.check_answer(
                                                                      slide
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Check")]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    slide.answered === true
                                                      ? [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                disabled: "",
                                                              },
                                                            },
                                                            [_vm._v("Check")]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }