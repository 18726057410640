import { render, staticRenderFns } from "./Mastery.vue?vue&type=template&id=faa750dc&scoped=true&"
import script from "./Mastery.vue?vue&type=script&lang=js&"
export * from "./Mastery.vue?vue&type=script&lang=js&"
import style0 from "./Mastery.vue?vue&type=style&index=0&id=faa750dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faa750dc",
  null
  
)

export default component.exports