var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { padding: "0px 12px 0px 12px" } },
    [
      _c(
        "v-row",
        {
          staticStyle: { "padding-top": "12px" },
          attrs: { justify: "center" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { elevation: "0" } },
                [
                  _c("v-card-title", [_vm._v("Exam")]),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-slider", {
                                attrs: {
                                  min: "3",
                                  max: "50",
                                  label: "Questions",
                                  "thumb-color": "primary",
                                  "thumb-label": "always",
                                  disabled: _vm.in_progress,
                                },
                                model: {
                                  value: _vm.exam_length,
                                  callback: function ($$v) {
                                    _vm.exam_length = $$v
                                  },
                                  expression: "exam_length",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "start" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.in_progress,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.begin_exam()
                                    },
                                  },
                                },
                                [_vm._v("Begin exam")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-stepper",
                {
                  attrs: { vertical: "" },
                  model: {
                    value: _vm.current_question,
                    callback: function ($$v) {
                      _vm.current_question = $$v
                    },
                    expression: "current_question",
                  },
                },
                _vm._l(_vm.exam, function (question, idx) {
                  return _c(
                    "div",
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: {
                            complete: _vm.current_question > question.step,
                            step: question.step,
                          },
                        },
                        [_vm._v("Question " + _vm._s(question.step))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: question.step } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-12", attrs: { elevation: "0" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticStyle: {
                                    "padding-bottom": "0",
                                    "word-break": "break-word",
                                  },
                                },
                                [_vm._v(_vm._s(question.text))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          model: {
                                            value: question.answer,
                                            callback: function ($$v) {
                                              _vm.$set(question, "answer", $$v)
                                            },
                                            expression: "question.answer",
                                          },
                                        },
                                        _vm._l(
                                          question.choices,
                                          function (option, idx) {
                                            return _c("v-radio", {
                                              key: idx,
                                              attrs: {
                                                label: option.text,
                                                value: idx,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _vm.exam_length !== _vm.current_question
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  question.answer === -1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.next_question()
                                                },
                                              },
                                            },
                                            [_vm._v("Continue")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.exam_length === _vm.current_question
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  question.answer === -1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.submit_exam()
                                                },
                                              },
                                            },
                                            [_vm._v("Submit")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }